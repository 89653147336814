import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { Checkbox, DatePicker, DatePickerProps, Drawer, Form, Input, Modal, Radio, Select, Spin, Tag } from 'antd';
import notificationShow from '../utils/notificationService';
import TextArea from 'antd/es/input/TextArea';
import { FaInfoCircle } from 'react-icons/fa';
import { dynamicFields } from '../pages/config';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

interface ISmSSenderContext {
	showMessageDrawer: any;
	showMessageTo: any;
}

export const SmSSenderContext = React.createContext<ISmSSenderContext>({
	showMessageDrawer: (conditions: any) => {},
	showMessageTo: (conditions: any) => {},
});

const SmSSenderContextFunction = ({ children }: any) => {
	const navigate = useNavigate();

	const [templatesData, setTemplatesData] = useState<any>([]);
	const [isOpensendSMSDrawer, setisOpensendSMSDrawer] = useState(false);
	const [openMessageTo, setOpenMessageTo] = useState(false);
	const [message, setMessage] = useState<string>('');
	const [groupsItem, setGroupsItem] = useState<any>([]);
	const [smsPreviewDrawerOpen, setSmsPreviewDrawerOpen] = useState<boolean>(false);
	const [sheduleMessageBtn, setSheduleMessageBtn] = useState<boolean>(true);
	const [sendMessageTo, setSendMessageTo] = useState<'all' | 'number' | 'groups' | 'contacts'>('number');
	const [messageSheduleDateTime, setMessageSheduleDateTime] = useState<any>(dayjs());
	const [smsPreviewData, setSmsPreviewData] = useState<any>([]);
	const [testResponseData, settestResponseData] = useState<any>({});
	const [groups, setGroups] = useState<any>(undefined);
	const [customNumber, setCustomNumber] = useState<any>(undefined);
	const [sending, setSending] = useState<any>(false);
	const [routes, setRoutes] = useState<any>([]);
	const [routeActive, setRouteActive] = useState<string>('');

	const [conditions, setConditions] = useState({});

	// useEffect(() => {
	// 	if (sendMessageTo === 'all' || sendMessageTo === 'groups') {
	// 		// getResponseWithConditions();
	// 	}
	// }, [sendMessageTo]);

	const getFormGroups = (checkedValues: CheckboxValueType[]) => {
		//@ts-ignore
		const selectedGroups = groupsItem.filter((el) => checkedValues.includes(el?._id?.toString())).map((el) => ({ label: el?.group_name, value: el?._id?.toString() }));

		setGroups(selectedGroups);
	};

	const getRoutes = async () => {
		try {
			const response = await axiosInstance.get(`/settings/route`);
			setRoutes(response.data);
		} catch (error: any) {
			console.error(error?.response?.data?.error);
		}
	};

	const getGroups = async () => {
		try {
			const response = await axiosInstance.get('/groups?showCount=true');

			setGroupsItem(response.data.data);
		} catch (error: any) {
			// notificationShow({
			// 	type: 'error',
			// 	content: error?.response?.data?.error,
			// });
			console.error(error?.response?.data?.error);
		}
	};

	const showMessageDrawer = async (conditions: any) => {
		setConditions(conditions);
		setSendMessageTo('contacts');
		setOpenMessageTo(false);
		await getResponseWithConditions(conditions);
	};

	const showMessageTo = async (conditions: any) => {
		setConditions(conditions);
		setOpenMessageTo(true);
		await getResponseWithConditions(conditions);
	};

	const getTemplates = async () => {
		try {
			const response = await axiosInstance.get('/templates');
			setTemplatesData(response.data);
		} catch (error: any) {
			console.error(error?.response?.data?.error);
		}
	};

	const handleMessageSheduleChange: DatePickerProps['onChange'] = (_, dateStr) => {
		setMessageSheduleDateTime(dateStr);
	};

	const getResponseWithConditions = async (conditions: any) => {
		try {
			let dataToTest: any = {
				is_test: true,
				message: message ? message : 'test',
				send_at: messageSheduleDateTime,
				conditions: {
					...conditions,
				},
			};

			const response = await axiosInstance.post('/sms/sendSMS', dataToTest);
			settestResponseData(response.data);
			setisOpensendSMSDrawer(true);
		} catch (error: any) {}
	};

	const handleMultipleMessageSend = async (confirm_send?: boolean) => {
		try {
			let groupMatchingCondition = {};
			if (groups && groups.length !== 0) {
				groupMatchingCondition = {
					$or: groups.map((el: any) => {
						return {
							groups: el.value.toString(),
						};
					}),
					// };
				};
			}

			let dataToSend: any = {
				message: message ? message : 'test',
				send_at: messageSheduleDateTime,
				route_id: routeActive === '' ? undefined : routeActive,
				conditions: {
					// ...(Object.keys(groupMatchingCondition).length !== 0 && {  }),
					...conditions,
					...groupMatchingCondition,
				},
			};

			if (confirm_send) {
				dataToSend.send_final = true;
				setSending(true);
			}

			const response = await axiosInstance.post('/sms/sendSMS', dataToSend);

			setSmsPreviewData(response.data);
			// setisOpensendSMSDrawer(false);
			// setOpenMessageTo(false);
			setSmsPreviewDrawerOpen(true);

			if (confirm_send) {
				setSmsPreviewDrawerOpen(false);
				setisOpensendSMSDrawer(false);
				setSendMessageTo('number');
				setMessage('');
				setRouteActive('');
				setOpenMessageTo(false);
				setSending(false);
				setGroups(undefined);

				notificationShow({
					type: 'success',
					content: 'SMS has been scheduled',
				});
				navigate('/sms-history');
			}
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data.message,
			});
		}
	};

	const handleNumberMessageSend = async () => {
		try {
			setSending(true);
			let dataToSend: any = {
				sms: message,
				numbers: customNumber.split('\n'),
				route_id: routeActive === '' ? undefined : routeActive,
			};

			await axiosInstance.post('/sms/sendSMSNumbersOnly', dataToSend);
			// await axiosInstance.post('/sms/sendSMSNumbersOnly', { sms: message, numbers: [customNumber] });

			setSmsPreviewDrawerOpen(false);
			setisOpensendSMSDrawer(false);
			setOpenMessageTo(false);
			setSending(false);
			setCustomNumber(undefined);
			setMessage('');
			setSendMessageTo('number');
			setGroups(undefined);
			notificationShow({
				type: 'success',
				content: 'SMS has been scheduled',
			});
			navigate('/sms-history');
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data.message,
			});
		}
	};

	useEffect(() => {
		getTemplates();
		getGroups();
		getRoutes();
	}, []);

	console.log(routes?.data?.[0]);

	return (
		<SmSSenderContext.Provider value={{ showMessageDrawer, showMessageTo }}>
			{children}

			<Drawer
				destroyOnClose={true}
				placement={'right'}
				maskClosable={false}
				className='!bg-white'
				title={'Send SMS'}
				closable={true}
				onClose={() => {
					setOpenMessageTo(false);
					setisOpensendSMSDrawer(false);
					setSmsPreviewDrawerOpen(false);
					setGroups(undefined);
					setCustomNumber(undefined);
					setMessage('');
					setSendMessageTo('number');
				}}
				open={isOpensendSMSDrawer}
				key={'sendsms'}
			>
				<div className={sendMessageTo === 'all' ? '' : 'hidden'}>
					<div className={`p-2 font-semibold bg-green-100 rounded-md`}>Found {testResponseData.totalContacts} contacts / numbers matching this condition.</div>
					<br />
					<hr />
					<br />
				</div>
				{openMessageTo && (
					<div className='p-2 rounded-md bg-PrimaryWhite'>
						<div className='mb-4'>
							<Form.Item required name='send_to' label={<div className='font-bold text-PrimaryBlack'>Sent To</div>} className='w-full !m-0' />
							<Radio.Group value={sendMessageTo} onChange={(e) => setSendMessageTo(e.target.value)}>
								<Radio value={'all'}>All Contacts</Radio>
								<Radio value={'number'}>Numbers</Radio>
								<Radio value={'groups'}>Groups</Radio>
							</Radio.Group>
						</div>
						<hr />

						{sendMessageTo === 'number' && (
							<>
								<Form.Item required name='numbers' label={<div className='font-bold text-PrimaryBlack'>Numbers</div>} className='w-full !m-0' />
								<TextArea rows={5} placeholder='Numbers seperated by new line.' onChange={(e) => setCustomNumber(e.target.value)} />
							</>
						)}

						{sendMessageTo === 'groups' && (
							<>
								{groupsItem?.length === 0 || !groupsItem ? (
									<p className='text-PrimaryBlack grid place-items-center font-semibold text-cneter'>There are no any groups yet!</p>
								) : (
									<>
										<Checkbox.Group
											className='my-4 flex flex-col space-y-4'
											onChange={getFormGroups}
											options={groupsItem.map((el: any) => {
												return {
													label: `${el.group_name} (${el.count})`,
													value: el._id?.toString(),
												};
											})}
										/>
									</>
								)}
							</>
						)}
					</div>
				)}
				<Form.Item name='choose_template' label={<div className='font-bold text-PrimaryBlack'>Choose Template</div>} className='w-full !m-0' />
				{templatesData?.data?.length !== 0 ? (
					<Select
						onChange={(value) => {
							const data = templatesData?.data?.find((item: any) => item?._id === value);
							data ? setMessage(data.message) : setMessage('');
						}}
						placeholder='Select a template'
						className='w-full'
						size='large'
						allowClear
						options={templatesData?.data?.map((item: any) => {
							return { label: item.template_name, value: item?._id };
						})}
					/>
				) : (
					<Input value={'None'} disabled />
				)}
				<Form.Item name='choose_template' label={<div className='font-bold text-PrimaryBlack'>Choose Route</div>} className='w-full !m-0' />
				{routes?.data?.length !== 0 ? (
					<Select
						onChange={(value) => {
							setRouteActive(value);
						}}
						placeholder='Select a route'
						className='w-full'
						size='large'
						defaultValue={routeActive}
						allowClear
						options={[
							{ label: 'Default', value: '' },
							...(routes?.data?.map((item: any) => {
								return { label: item.name, value: item._id };
							}) || []),
						]}
					/>
				) : (
					<Input value={'None'} disabled />
				)}
				<Form.Item required name='Message' label={<div className='font-bold text-PrimaryBlack'>Message</div>} className='w-full !m-0' />
				<TextArea
					rows={4}
					onChange={(e) => {
						setMessage(e.target.value);
					}}
					value={message}
				/>
				<div className={`mt-4 ${openMessageTo && sendMessageTo === 'number' ? 'hidden' : ''}`}>
					<div>
						<Form.Item required name='dynamic_fields' label={<div className='font-bold text-PrimaryBlack'>Dynamic Fields</div>} className='w-full !m-0' />
						<div className='mb-4 flex flex-wrap'>
							{dynamicFields.map((item: any, i) => (
								<Tag key={i} color={item.color} className='m-1 cursor-pointer' onClick={() => setMessage(message + item.value)}>
									{item.name}
								</Tag>
							))}
						</div>
					</div>
					<div className='hidden'>
						<Form.Item required name='send_at' label={<div className='font-bold text-PrimaryBlack'>Sent At</div>} className='w-full !m-0' />
						<Radio.Group className={!sheduleMessageBtn ? 'mb-4' : ''} value={sheduleMessageBtn} onChange={(e) => setSheduleMessageBtn(e.target.value)}>
							<Radio value={true}>Now</Radio>
							<Radio value={false}>Later</Radio>
						</Radio.Group>
						{!sheduleMessageBtn && <DatePicker className='w-[100%]' showTime onChange={handleMessageSheduleChange} format='YYYY-MM-DD, h:mm:a' defaultValue={messageSheduleDateTime} />}
					</div>
				</div>

				<div className='flex gap-2'>
					<button
						onClick={() => {
							setisOpensendSMSDrawer(false);
							setOpenMessageTo(false);
						}}
						className='bg-gray-200 text-gray-600 py-2 px-4 font-semibold rounded mt-4'
					>
						Cancel
					</button>

					<button
						onClick={() => {
							openMessageTo && sendMessageTo === 'number' ? handleNumberMessageSend() : handleMultipleMessageSend(true);
						}}
						className={`${sending ? 'hidden' : ''} bg-PrimaryOrange text-PrimaryWhite py-2 px-4 font-semibold rounded mt-4`}
					>
						Send SMS
					</button>
					<Spin spinning={sending} />
					<button
						onClick={() => {
							handleMultipleMessageSend();
						}}
						className={`		${sendMessageTo === 'number' ? 'hidden' : ''}  text-PrimaryBlue underline underline-offset-4 py-2 px-4 font-semibold rounded mt-4`}
					>
						Preview SMS
					</button>
				</div>
			</Drawer>
			<Modal
				okButtonProps={{ className: 'hidden' }}
				cancelText={'Go Back'}
				centered
				className='!bg-white !z-50'
				title={'Preview SMS'}
				closable={true}
				onCancel={() => setSmsPreviewDrawerOpen(false)}
				open={smsPreviewDrawerOpen}
				key={'sendsms'}
			>
				{smsPreviewData &&
					smsPreviewData.preview &&
					smsPreviewData.preview.map((el: any) => (
						<div className='bg-gray-50 rounded-xl p-2 mt-2'>
							<b>Contact:</b> {el.contact_name} ({el.number}) <br />
							<b>SMS:</b> {el.message}
						</div>
					))}

				<div className='font-bold'>
					{' '}
					<br />
					{smsPreviewData.totalContacts - 3 > 0 && <>... & {smsPreviewData.totalContacts - 3} more</>}
				</div>

				{/* <div className='text-bold text-gray-600 flex gap-1 items-center'> </div> */}

				{/* <button
					onClick={() => {
						handleMultipleMessageSend();
					}}
					className='bg-PrimaryOrange text-PrimaryWhite py-2 px-4 font-semibold rounded mt-4'
				>
					Preview SMS
				</button> */}
				{/* <button
					// onClick={() => {
					// 	setSmsPreviewDrawerOpen(false);
					// }}
					className='bg-PrimaryWhite text-black py-2 px-4 font-semibold rounded'
				>
					Go Back
				</button> */}
			</Modal>
		</SmSSenderContext.Provider>
	);
};

export default SmSSenderContextFunction;
