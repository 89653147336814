import { Navigate, Route, Routes } from 'react-router-dom';
import SignIn from './pages/SignIn';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgetPassword';
import Dashboard from './pages/user/Dashboard';
import Error from './pages/Error';
import Contacts from './pages/user/Contacts';
import { userContext } from './context/userContext';
import decodeAccessToken from './utils/decodedAccessToken';
import SMSQueue from './pages/user/SMSQueue';
import { useContext, useEffect } from 'react';
import SMSHistory from './pages/user/SMSHistory';
import HandShake from './pages/user/HandShake';
import Setting from './pages/Setting';
import UpgradePlan from './pages/UpgradePlan';
import MessageTemplate from './pages/user/MessageTemplate';
import ManageGroups from './pages/user/ManageGroups';
import BulkSMS from './pages/user/BulkSMS';
import ImportContact from './pages/user/ImportContact';
import InstallClient from './pages/user/InstallClient';
import ManageAPI from './pages/user/ManageAPI';
import VerifyPurchase from './pages/VerifyPurchase';
import Transfer from './pages/user/Transfer';
import ManageRoutes from './pages/user/ManageRoutes';
import ResetPassword from './pages/ResetPassword';

const App = () => {
	const accessToken = localStorage.getItem('accessToken');
	const token: any = decodeAccessToken(accessToken);
	const userContextData: any = useContext(userContext);

	useEffect(() => {
		if (token && token !== null && Object.keys(token).length !== 0) {
			userContextData.getConfig();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Routes>
			<Route path='/' element={token ? <Navigate to={'/dashboard'} replace={true} /> : <Login />} />
			<Route path='/handshake' element={<HandShake />} />
			<Route path='/sign-up' element={<SignIn />} />
			<Route path='/install-client' element={<InstallClient />} />
			<Route path='/forgot-password' element={<ForgotPassword />} />
			<Route path='/reset-password/:email' element={<ResetPassword />} />
			<Route path='/dashboard' element={<Dashboard />} />
			<Route path='/settings/sms-templates' element={<MessageTemplate />} />
			<Route path='/settings' element={<Setting />} />
			<Route path='/settings/upgrade' element={<UpgradePlan />} />
			<Route path='/verify_purchase' element={<VerifyPurchase />} />
			<Route path='/contacts' element={<Contacts />} />
			<Route path='/contacts/import' element={<ImportContact />} />
			<Route path='/sms-history/queue' element={<SMSQueue />} />
			<Route path='/sms-history' element={<SMSHistory />} />
			<Route path='/settings/manage-groups' element={<ManageGroups />} />
			<Route path='/settings/manage-api' element={<ManageAPI />} />
			<Route path='/settings/transfer' element={<Transfer />} />
			<Route path='/settings/manage-routes' element={<ManageRoutes />} />
			{/* <Route path='/send-sms' element={<BulkSMS />} /> */}

			<Route path='*' element={<Error />} />
		</Routes>
	);
};

export default App;
