// @ts-nocheck
import { useContext, useEffect, useState } from 'react';
import UserWrapper from '../../components/UserWrapper';
import axiosInstance from '../../utils/axiosInstance';
import { Spin, Tag } from 'antd';
import notificationShow from '../../utils/notificationService';
import { useNavigate } from 'react-router-dom';
import Compact from 'antd/es/space/Compact';
import { FaMobileAlt } from 'react-icons/fa';
import ProgessBar from '../../components/ProgessBar';
import { userContext } from '../../context/userContext';
import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime');

const Dashboard = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [dashboardDetails, setDashboardDetails] = useState<any>({});
	const userContextData: any = useContext(userContext);

	const navigate = useNavigate();

	const getConfig = async () => {
		if (localStorage.getItem('accessToken')) {
			try {
				const response = await axiosInstance.get('users/get-config');
				if (!response.data.data.client_connected) {
					navigate('/install-client', { state: { CompanyName: response.data.data.name } });
				}
			} catch (error: any) {
				console.error(error);
			}
		}
	};

	useEffect(() => {
		userContextData.getConfig();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getDetails = async () => {
		try {
			setLoading(true);

			const res = await axiosInstance.get('/reports/dashboard?current_time=' + dayjs().toISOString());

			setDashboardDetails(res.data);
			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.message,
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getConfig();
		getDetails();
	}, []);

	console.log(userContextData?.userDetails?.client_last_ping);

	const receivedTimestamp = new Date(userContextData?.userDetails?.client_last_ping);

	// Current timestamp
	const currentTimestamp = new Date();

	// Calculate the difference in milliseconds
	const differenceInMilliseconds = currentTimestamp - receivedTimestamp;
	let lastPing = '';

	try {
		lastPing = dayjs(userContextData?.userDetails?.client_last_ping).fromNow().toString();
	} catch (e) {
		lastPing = 'Not Available';
	}

	return (
		<UserWrapper>
			<Spin spinning={loading}>
				<div className='flex justify-between flex-col space-y-4 md:space-y-0 md:flex-row max-h-[10vh] m-4 items-center'>
					<span className=' font-bold text-[18px] text-gray-600'>Dashboard:</span>
				</div>
				<div className='flex items-center justify-between space-x-6 mx-4 mb-4'>
					<div className='w-[80%]'>
						<ProgessBar usedSMS={userContextData?.userDetails?.sms_limit_sent} totalSMS={userContextData?.userDetails?.sms_limit} />
					</div>

					<div className='flex items-center space-x-2 text-PrimaryBlack w-[20rem] '>
						<div>
							<FaMobileAlt size={40} />
						</div>
						<div>
							<p className='font-semibold'>Android Device Status:</p>
							<p>
								Last sync: <Tag color={differenceInMilliseconds < 120000 ? 'green' : 'red'}>{lastPing}</Tag>
							</p>
						</div>
					</div>
				</div>
				<hr />
				<div className='grid md:grid-cols-3 grid-cols-1 gap-4 m-4'>
					<div className='p-4 bg-PrimaryWhite text-PrimaryBlack'>
						<div>Contacts</div>
						<div className='text-2xl font-semibold'>{dashboardDetails?.contacts}</div>
					</div>

					<div className='p-4 bg-PrimaryWhite text-PrimaryBlack'>
						<div>Groups</div>
						<div className='text-2xl font-semibold'>{dashboardDetails?.groups}</div>
					</div>
				</div>
				<hr />
				<div className='grid md:grid-cols-3 grid-cols-1 gap-4 m-4'>
					<b> SMS SENT: </b>
				</div>
				<div className='grid md:grid-cols-3 grid-cols-1 gap-4 m-4'>
					<div className='p-4 bg-orange-100 text-PrimaryBlack'>
						<div>Today</div>
						<div className='text-2xl font-semibold'>{dashboardDetails?.todaySms}</div>
					</div>

					<div className='p-4 bg-PrimaryWhite text-PrimaryBlack'>
						<div>Yesterday</div>
						<div className='text-2xl font-semibold'>{dashboardDetails?.yestardaySms}</div>
					</div>

					<div className='p-4 bg-PrimaryWhite text-PrimaryBlack'>
						<div>This Month</div>
						<div className='text-2xl font-semibold'>{dashboardDetails?.thisMonthSMS}</div>
					</div>

					<div className='p-4 bg-PrimaryWhite text-PrimaryBlack'>
						<div>This Year</div>
						<div className='text-2xl font-semibold'>{dashboardDetails?.thisYearSms}</div>
					</div>
				</div>
			</Spin>
		</UserWrapper>
	);
};

export default Dashboard;
