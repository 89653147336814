import React, { useEffect, useState } from 'react';
import UserWrapper from '../../components/UserWrapper';
import { Drawer, Popconfirm, Spin, Form, Input, Tag, Modal, Empty } from 'antd';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import axiosInstance from '../../utils/axiosInstance';
import notificationShow from '../../utils/notificationService';
import TextArea from 'antd/es/input/TextArea';
import { dynamicFields } from '../config';

const ManageGroups = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [groupsData, setgroupsData] = useState<any>([]);
	const [openAddGroupModel, setOpenAddGroupModel] = useState<boolean>(false);

	const [isModifyDialog, setIsModifyDialog] = useState(false);
	const [groupModifyId, setgroupModifyId] = useState('');

	// Gropus...

	const [selectedGroupColor, setSelectedGroupColor] = useState<string>('');

	const [addGroup] = Form.useForm();

	const customColor = ['magenta', 'red', 'volcano', 'orange', 'gold', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple'];

	const getGroups = async () => {
		try {
			setLoading(true);

			const response = await axiosInstance.get('/groups');

			setgroupsData(response.data.data);
			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.error,
			});
			setLoading(false);
		}
	};

	const handleGroupDelete = async (id: string) => {
		try {
			setLoading(true);

			await axiosInstance.delete(`/groups/${id}`);

			notificationShow({
				type: 'success',
				content: 'Group deleted Successfully.',
			});

			getGroups();
			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.message,
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getGroups();
	}, []);

	const handleAddEditGroup = async () => {
		try {
			setLoading(true);

			const formValues = addGroup.getFieldsValue();

			let response;

			if (isModifyDialog) {
				response = await axiosInstance.patch('/groups', {
					group_id: groupModifyId,
					group_name: formValues.group_name,
					color: selectedGroupColor,
				});
			} else {
				response = await axiosInstance.post('/groups', {
					group_name: formValues.group_name,
					color: selectedGroupColor,
				});
			}

			notificationShow({
				type: 'success',
				content: response && response.data && response.data.message,
			});
			window.location.reload();
			addGroup.resetFields();
			getGroups();
			setLoading(false);
			setSelectedGroupColor('');
			setOpenAddGroupModel(false);
		} catch (e: any) {
			if (e && e.response && e.response.data && e.response.data.message) {
				notificationShow({
					type: 'error',
					content: e?.response?.data.message,
				});
			} else {
				notificationShow({
					type: 'error',
					content: 'Connection Failed',
				});
			}

			setLoading(false);
		}
	};

	return (
		<UserWrapper>
			<Spin spinning={loading}>
				<div className='flex justify-between flex-col space-y-4 md:space-y-0 md:flex-row max-h-[10vh] m-4 items-center'>
					<span className=' font-bold text-[18px] text-gray-600'>Manage Groups:</span>
					<button
						onClick={() => {
							setIsModifyDialog(false);
							setOpenAddGroupModel(true);
						}}
						className='bg-PrimaryOrange text-PrimaryWhite py-2 px-4 font-semibold rounded'
					>
						Add Group
					</button>
				</div>
				<div className=' h-[75vh] overflow-y-scroll'>
					{' '}
					{groupsData?.length > 0 ? (
						groupsData?.map((template: any) => (
							<div className='p-4 bg-gray-50 text-PrimaryBlack max-w-[800px] mb-4 flex justify-between rounded-md'>
								<div className='text-justify mr-2'>
									<div className=' flex items-center gap-2 font-semibold '>
										<div>
											<Tag color={template.color} className='font-bold   h-[20px] w-[20px] rounded-full'></Tag>
										</div>

										<div>{template?.group_name} </div>
									</div>
								</div>
								<div className=' min-w-[80px]'>
									<button
										onClick={() => {
											setIsModifyDialog(true);
											setOpenAddGroupModel(true);
											setgroupModifyId(template._id.toString());

											addGroup.setFieldsValue({
												group_name: template.group_name,
											});
										}}
										className='bg-gray-400 mr-2 p-2 rounded-md text-xl text-PrimaryWhite'
									>
										<FaEdit />
									</button>
									<Popconfirm
										title='Delete Template'
										placement='bottomRight'
										description='Are you sure to delete template?'
										okText='Yes'
										cancelText='No'
										okButtonProps={{ className: 'bg-red-400' }}
										onConfirm={() => handleGroupDelete(template._id)}
									>
										<button className='bg-red-600 p-2 rounded-md text-xl text-PrimaryWhite'>
											<MdDelete />
										</button>
									</Popconfirm>
								</div>
							</div>
						))
					) : (
						<div className='text-center font-semibold bg-gray-50 py-4'>
							<Empty description='No groups yet!' />
						</div>
					)}
				</div>
			</Spin>

			<Modal
				title={isModifyDialog ? 'Edit group' : 'Add Group'}
				okText={isModifyDialog ? 'Edit group' : 'Add Group'}
				okButtonProps={{
					className: '!bg-PrimaryBlue !text-PrimaryWhite font-semibold',
				}}
				centered
				open={openAddGroupModel}
				onOk={handleAddEditGroup}
				onCancel={() => {
					setOpenAddGroupModel(false);
					addGroup.resetFields();
					setSelectedGroupColor('');
				}}
			>
				<Form autoComplete='off' size='small' layout='vertical' form={addGroup} className='w-full'>
					<Form.Item
						required
						name='group_name'
						label={<div className='font-bold text-PrimaryBlack'>Group Name</div>}
						rules={[
							{
								required: true,
								message: 'group name is required.',
							},
						]}
						className='w-full'
					>
						<Input type='text' className='border border-gray-300 rounded-md w-full py-2 px-4' />
					</Form.Item>
					<Form.Item
						required
						name='color'
						label={<div className='font-bold text-PrimaryBlack'>Choose Color</div>}
						rules={[
							{
								required: true,
								message: 'color is required.',
							},
						]}
						className='w-full'
					>
						<div className='flex flex-wrap'>
							{customColor.map((color, i) => (
								<Tag key={i} color={color} className={`cursor-pointer m-1 ${selectedGroupColor === color && 'font-extrabold'}`} onClick={() => setSelectedGroupColor(color)}>
									{color}
								</Tag>
							))}
						</div>
					</Form.Item>
				</Form>
			</Modal>
		</UserWrapper>
	);
};

export default ManageGroups;
