import axios from 'axios';

const axiosInstance = axios.create({
	// baseURL: 'http://192.168.101.2:8000/api/v1',
	// baseURL: "http://localhost:8000/api/v1",
	baseURL: 'https://api.txtbond.com/api/v1',
	// baseURL: 'http://13.233.233.118:8000/api/v1',

	timeout: 10000,
	headers: {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
	},
});

export default axiosInstance;
