import UserWrapper from '../components/UserWrapper';
import { FaChevronRight } from 'react-icons/fa';
import notificationShow from '../utils/notificationService';
import axiosInstance from '../utils/axiosInstance';
import { useState } from 'react';
import { IoMdCall } from 'react-icons/io';
import { Spin, Tag } from 'antd';

const UpgradePlan = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const handleUpgradePlan = async (sms_limit: any) => {
		try {
			setLoading(true);

			const response: any = await axiosInstance.post('/settings/payEsewa', { sms_limit: sms_limit });

			// Esewa redirection logic....
			var path = 'https://epay.esewa.com.np/api/epay/main/v2/form';

			const params: any = {
				amount: response.data.amount_without_vat,
				tax_amount: response.data.vat_amount,
				total_amount: response.data.amount_with_vat,
				transaction_uuid: response.data.uuid,
				product_code: 'NP-ES-PALSVOICE',
				product_service_charge: 0,
				product_delivery_charge: 0,
				signature: response.data.signature,
				signed_field_names: 'total_amount,transaction_uuid,product_code',
				success_url: 'https://app.txtbond.com/verify_purchase/?payment=done',
				failure_url: 'https://app.txtbond.com/failed',
			};

			var form = document.createElement('form');
			form.setAttribute('method', 'POST');
			form.setAttribute('action', path);

			for (var key in params) {
				var hiddenField = document.createElement('input');
				hiddenField.setAttribute('type', 'hidden');
				hiddenField.setAttribute('name', key);
				hiddenField.setAttribute('value', params[key]);
				form.appendChild(hiddenField);
			}

			document.body.appendChild(form);
			form.submit();
			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data.message,
			});
			setLoading(false);
		}
	};

	return (
		<UserWrapper>
			<Spin spinning={loading}>
				<div className='flex justify-between flex-col space-y-6 md:space-y-0 md:flex-row max-h-[10vh] m-4 items-center'>
					<span className=' font-bold text-[18px] text-gray-600'>Extend:</span>
				</div>
				<div className='grid grid-cols-12  gap-4 h-full mx-4'>
					<div className='md:col-span-2'></div>
					<div
						onClick={() => handleUpgradePlan(10000)}
						className='md:col-span-4  col-span-12 cursor-pointer bg-PrimaryWhite  px-4 text-PrimaryBlack py-6 flex flex-col space-y-2 text-center rounded-md w-full'
					>
						<h1 className='text-xl font-semibold'>10,000</h1>
						<p>SMS Volume</p>
						<hr />
						<h1 className='text-2xl font-semibold'>Rs. 1,000</h1>
					</div>
					<div
						onClick={() => handleUpgradePlan(50000)}
						className='md:col-span-4  col-span-12 relative cursor-pointer bg-green-100 px-4 text-PrimaryBlack py-6 flex flex-col space-y-2 text-center rounded-md w-full'
					>
						<h1 className='text-xl font-semibold'>50,000</h1>
						<p>SMS Volume</p>
						<div className='flex justify-center'>
							<Tag color='green' className='font-bold  origin-bottom absolute top-2 right-0 rotate-12'>
								MOST POPULAR
							</Tag>
						</div>
						<hr />
						<h1 className='text-2xl font-semibold'>Rs. 4,000</h1>
					</div>
					<div className='md:col-span-2'></div>
					{/* <div
						onClick={() => handleUpgradePlan(150000)}
						className='md:col-span-4  col-span-12 cursor-pointer bg-orange-100 px-4 text-PrimaryBlack py-6 flex flex-col space-y-2 text-center rounded-md w-full'
					>
						<h1 className='text-xl font-semibold'>1,50,000</h1>
						<p>SMS limit</p>
						<hr />
						<h1 className='text-2xl font-semibold'>Rs. 10,000</h1>
					</div> */}
				</div>
				<div className=' flex justify-center'>
					<div className='rounded-md'>
						<div className='py-6 px-8 mt-4 flex flex-col  font-semibold   text-PrimaryBlack'>
							<div className='bg-orange-100 items-center rounded-md px-6 text-center flex flex-col space-y-4  py-4'>
								<h1 className='mb-2 font-bold text-2xl text-center'>All Plans Includes:</h1>

								<div className='inline-flex  items-center '>* API Integration</div>

								<div className='inline-flex items-center'>* Unlimited Contacts</div>

								<div className='inline-flex items-center'>* Standard Support</div>
							</div>

							<div className='text-center px-2 py-3 rounded-md'>
								Need help? contact us: <br />
								<a className=' inline-flex items-center text-PrimaryBlue  hover:text-PrimaryBlue' href='tel:015970649'>
									<IoMdCall className='mr-1' /> 01-5970649
								</a>
							</div>
						</div>
					</div>
				</div>
			</Spin>
		</UserWrapper>
	);
};

export default UpgradePlan;
