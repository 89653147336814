// @ts-nocheck
import React, { useContext, useState } from 'react';
import Logo from '../components/Logo';
import { FaBars, FaCrown, FaHistory, FaUsers, FaRegQuestionCircle } from 'react-icons/fa';
import { RiSendPlaneFill } from 'react-icons/ri';
import { Avatar, Button, Divider, Drawer, Flex, Popconfirm, Popover, Tag } from 'antd';
import { IoSettingsSharp } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import { MdHome, MdContacts } from 'react-icons/md';
import { LuUser2 } from 'react-icons/lu';
import { MdLogout, MdMessage } from 'react-icons/md';
import { userContext } from '../context/userContext';
import dayjs from 'dayjs';
import { SmSSenderContext } from '../context/smsSenderContext';
import ProgessBar from './ProgessBar';
var relativeTime = require('dayjs/plugin/relativeTime');

const navlinks = [
	{
		label: 'Dashboard',
		icon: <MdHome />,
		link: '/dashboard',
	},
	// {
	// 	label: 'Send SMS',
	// 	icon: <MdMessage />,
	// 	link: '/send-sms',
	// },
	{
		label: 'Contacts',
		icon: <MdContacts />,
		link: '/contacts',
	},
	{
		label: 'SMS History',
		icon: <FaHistory />,
		link: '/sms-history',
	},

	{
		label: 'Settings',
		icon: <IoSettingsSharp />,
		link: '/settings',
	},
];

const handleLogout = () => {
	localStorage.removeItem('accessToken');
	window.location.href = '/';
};

const Sidebar = ({ time }) => {
	return (
		<div className='px-1 h-full md:h-[100dvh] flex flex-col sticky left-0'>
			<div className='flex justify-center py-2'>
				<Logo />
			</div>
			<Divider className='mt-2' />
			<div className=' flex flex-col space-y-1'>
				{navlinks.map((link, i) => {
					return (
						<NavLink
							key={i}
							to={link.link}
							className={({ isActive }) =>
								` ${
									isActive && 'bg-PrimaryOrange text-PrimaryWhite font-bold'
								}   flex items-center p-3 text-PrimaryBlack font-semibold rounded-sm duration-500 ease-in-out hover:bg-PrimaryOrange hover:text-PrimaryWhite hover:font-bold`
							}
						>
							<span className='mr-2'>{link.icon}</span>
							{link.label}
						</NavLink>
					);
				})}
			</div>
			{/* <div className='flex items-end h-full mb-2'>
				{' '}
				<div className='flex flex-col space-y-3'>
					<Tag color='blue'>
						<span className='font-bold'>Android last ping:</span> {dayjs(time).fromNow().toString()}
					</Tag>

					<a href='https://txtbond.com/ping' target='_blank' className='block flex justify-end mr-2'>
						<FaRegQuestionCircle className='text-PrimaryBlack' />
					</a>
				</div>
			</div> */}
		</div>
	);
};

const Index = ({ children }: { children: React.ReactNode }) => {
	const userContextData: any = useContext(userContext);
	const smsSenderContext = useContext(SmSSenderContext);

	const [openNav, setOpenNav] = useState(false);
	const [open, setOpen] = useState(false);

	const targetDate = new Date(userContextData?.userDetails?.expires_at).getTime();
	const currentDate = new Date().getTime();
	const differenceMs = targetDate - currentDate;
	dayjs.extend(relativeTime);

	// Convert milliseconds to days
	const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

	const content = (
		<div className='px-2'>
			<h1 className='text-center text-xl font-bold text-PrimaryBlack'>
				{userContextData?.userDetails?.name} <br />
				<span className='text-[0.8rem] font-medium'>{userContextData?.userDetails?.email}</span>
			</h1>
			<Divider className='my-4' />
			<Popconfirm title='Logout' placement='bottomRight' description='Are you sure to logout?' okText='Yes' cancelText='No' okButtonProps={{ className: 'bg-red-400' }} onConfirm={handleLogout}>
				<p className='flex items-center  font-bold text-xl text-PrimaryBlack cursor-pointer'>
					<MdLogout className='mr-2' /> Logout
				</p>
			</Popconfirm>
		</div>
	);

	return (
		<div className='grid grid-cols-12 gap-2  max-h-[100vh]  overflow-hidden'>
			<div className='lg:col-span-2 md:col-span-3 bg-PrimaryWhite hidden md:block'>
				<Sidebar time={userContextData?.userDetails?.client_last_ping} />
			</div>

			<div className='md:hidden'>
				<Drawer placement={'left'} className='!bg-PrimaryWhite !w-[70dvw]' closable={false} onClose={() => setOpenNav(false)} open={openNav} key={'left'}>
					<Sidebar time={userContextData?.userDetails?.client_last_ping} />
				</Drawer>
			</div>

			<div className='lg:col-span-10 md:col-span-9 col-span-12 '>
				<Flex align='center' justify='space-between' className='border-b-[1px] mx-2 py-4  h-[10vh] mb-2'>
					<Button onClick={() => setOpenNav(!openNav)} className=' md:hidden text-PrimaryBlack ' type='text'>
						<FaBars className='text-xl' />
					</Button>
					<div className='flex items-center'>
						<h1 className=' md:ml-0 font-bold text-xl text-PrimaryBlack'>{userContextData?.userDetails?.business_name}</h1>
					</div>
					<div>
						<button onClick={() => smsSenderContext.showMessageTo()} className='bg-PrimaryOrange px-4 py-2 text-PrimaryWhite font-semibold mr-4 rounded-md inline-flex  items-center'>
							<RiSendPlaneFill className='mr-2' /> Send SMS
						</button>
						<Popover placement='bottomRight' trigger='click' open={open} onOpenChange={() => setOpen(!open)} content={content}>
							<Avatar className='bg-PrimaryBlue mr-2 cursor-pointer' icon={<LuUser2 />} />
						</Popover>
					</div>
				</Flex>
				{children}
			</div>
		</div>
	);
};

export default Index;
