import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ".//index.css";
import { BrowserRouter } from "react-router-dom";
import UserContextFunction from "./context/userContext";
import SmSSenderContextFunction from "./context/smsSenderContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <UserContextFunction>
      <SmSSenderContextFunction>
        <App />
      </SmSSenderContextFunction>
    </UserContextFunction>
  </BrowserRouter>
);
