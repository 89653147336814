import React, { useEffect } from 'react';
import notificationShow from '../../utils/notificationService';
import { Spin, message } from 'antd';
import axiosInstance from '../../utils/axiosInstance';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../components/Logo';
import { LoadingOutlined } from '@ant-design/icons';
const InstallClient = () => {
	const [userData, setUserData] = React.useState<any>([]);
	const location = useLocation();
	const state = location.state;

	// Accessing the value of CompanyName from the state
	const companyName = state ? state.CompanyName : null;

	const handleLogout = () => {
		localStorage.removeItem('accessToken');
		window.location.href = '/';
	};

	const getConfig = async () => {
		if (localStorage.getItem('accessToken')) {
			try {
				const response = await axiosInstance.get('users/get-config');
				setUserData(response.data.data);
				if (!response.data.data.client_connected) {
				} else {
					notificationShow({
						type: 'success',
						content: 'Android device linked successfully!',
					});
					window.location.replace('/dashboard');
				}
			} catch (error: any) {
				console.error(error);
			}
		}
	};

	useEffect(() => {
		getConfig();
		setInterval(() => {
			getConfig();
		}, 3000);
	}, []);

	return (
		<div className='grid place-items-center h-[100vh]'>
			<div className='w-[100v2] text-PrimaryBlack'>
				<div className='mb-2 flex justify-center items-start'>
					<h1 className='text-center text-xl font-bold text-PrimaryBlack'> {companyName && companyName}</h1>
					<span className='cursor-pointer ml-2 underline underline-offset-4' onClick={handleLogout}>
						Logout
					</span>
				</div>
				<div className=' rounded-md px-4 py-6 text-PrimaryBlack'>
					<div className='mb-8 flex justify-center'>
						<Logo />
					</div>
					<div className='text-center mb-8'>
						<h1 className='mb-6 font-bold text-2xl'>Last step: Please link your android device</h1>
						<p>Please follow these steps:</p>
					</div>
					<div className='flex flex-col space-y-4 mb-8'>
						<div className='flex space-x-2 items-center'>
							<div className='font-bold text-xl'>1.</div>
							<div className=''>
								Go to <span className='font-bold'>txtbond.com</span> from your android device.
							</div>
						</div>
						<div className='flex space-x-2 items-center'>
							<div className='font-bold text-xl'>2.</div>
							<div className=''>Download txtbond apk.</div>
						</div>
						<div className='flex space-x-2 items-center'>
							<div className='font-bold text-xl'>3.</div>
							<div className=''>
								Install & login this account (<span className='font-semibold text-red-500'>{userData?.email}</span>) on android app.
							</div>
						</div>
					</div>
					<p className='mb-8 text-center text-[18px] font-bold text-gray-500'>Waiting for you to login this account on Android...</p>
					<div className='flex justify-center'>
						<Spin size='large' indicator={<LoadingOutlined style={{ fontSize: 45 }} />} />
					</div>
					<div className='text-center mt-5'>
						Need help setting up? This video will help: <br />
						<iframe
							width='560'
							height='315'
							src='https://www.youtube.com/embed/aE54QQ2m6V0?si=g4DSwBKRLFPnaJYo&amp;start=78'
							title='YouTube video player'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						></iframe>
					</div>
					{/* <div>
					<p className='text-center font-semibold'>Having issues?</p>
					<p className='mb-4 text-center'>Have a look at this turtorial</p>
					<div>here goes turtorial</div>
				</div> */}
				</div>
			</div>
		</div>
	);
};

export default InstallClient;
