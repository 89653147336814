import { Input, Form, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import decodeAccessToken from '../utils/decodedAccessToken';
import axiosInstance from '../utils/axiosInstance';
import notificationShow from '../utils/notificationService';
import Logo from '../components/Logo';

const SignIn = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	useEffect(() => {
		const accessToken = localStorage.getItem('accessToken');
		const token: any = decodeAccessToken(accessToken);

		if (accessToken && token !== null && token !== undefined) {
			window.location.href = token && '/dashboard';
		}
	});

	const signup = async () => {
		try {
			setLoading(true);

			await form.validateFields();
			const formValues = form.getFieldsValue();

			const response = await axiosInstance.post('/users/register', {
				email: formValues.email,
				password: formValues.password,
				name: formValues.name,
				business_name: formValues.business_name,
				business_contact_number: formValues.business_contact_number,
				confirm_password: formValues.confirm_password,
			});

			localStorage.setItem('accessToken', response.data.accessToken);
			const accessToken = localStorage.getItem('accessToken');
			const tokenSuccess: any = decodeAccessToken(accessToken);
			window.location.href = tokenSuccess && '/dashboard';
			notificationShow({
				type: 'success',
				content: 'User registered Successfully.',
			});

			form.resetFields();
			setLoading(false);
		} catch (error: any) {
			setError(error?.response?.data?.message);
			setLoading(false);
		}
	};

	return (
		<div className='flex flex-col md:items-center m-4 md:mx-0 justify-center min-h-[100dvh] gap-4 '>
			<div className='flex justify-center'>
				<Logo />
			</div>
			<div className='bg-white border border-gray-200 shadow'>
				<div className=' p-4 lg:w-[30dvw] md:w-[50dvw] w-full'>
					<div className='w-full flex flex-col'>
						<Form autoComplete='off' size='small' layout='vertical' form={form} className='w-full'>
							<Form.Item
								required
								name='email'
								label={<div className='font-bold text-PrimaryBlack'>Email Address</div>}
								rules={[
									{
										required: true,
										message: 'Email is required.',
									},
									{
										pattern: /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
										message: 'Invalid email format.',
									},
								]}
								className='w-full'
							>
								<Input type='email' className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>
							<Form.Item
								required
								name='name'
								label={<div className='font-bold text-PrimaryBlack'>Name</div>}
								rules={[
									{
										required: true,
										message: 'Name is required.',
									},
								]}
								className='w-full'
							>
								<Input type='text' className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>
							<Form.Item
								required
								name='business_name'
								label={<div className='font-bold text-PrimaryBlack'>Business Name</div>}
								rules={[
									{
										required: true,
										message: 'Business name is required.',
									},
								]}
								className='w-full'
							>
								<Input type='text' className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>
							<Form.Item
								required
								name='business_contact_number'
								label={<div className='font-bold text-PrimaryBlack'>Business Contact Number</div>}
								rules={[
									{
										required: true,
										message: 'Business contact number is required.',
									},
								]}
								className='w-full'
							>
								<Input type='number' className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>
							<Form.Item
								name='password'
								label={<div className='font-bold text-PrimaryBlack'>Password</div>}
								rules={[
									{
										required: true,
										message: 'Password is required.',
									},
								]}
								className='w-full'
							>
								<Input.Password className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>
							<Form.Item
								name='confirm_password'
								label={<div className='font-bold text-PrimaryBlack'>Confirm Password</div>}
								rules={[
									{
										required: true,
										message: 'Confirm Password is required.',
									},
								]}
								className='w-full'
							>
								<Input.Password className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>
						</Form>

						<Spin spinning={loading}>
							<button onClick={signup} className='bg-PrimaryOrange text-white font-semibold px-4 py-2 rounded-md w-full my-4'>
								Sign Up
							</button>
						</Spin>
						{error && <div className='bg-red-50 text-red-500 rounded-md text-sm text-center py-2 mb-4'>{error}</div>}

						<div className='text-center text-sm text-PrimaryBlack'>
							Already have an account? <br />
							<Link to='/' className='cursor-pointer font-bold underline underline-offset-4'>
								Login to your account
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div className='text-[12px] text-PrimaryBlack text-center px-4'>
				For assistance, contact our support team at{' '}
				<a href='mailto:txtbond@gmail.com' className='font-bold '>
					txtbond@gmail.com.
				</a>
			</div>
		</div>
	);
};

export default SignIn;
