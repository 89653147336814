import React, { useContext } from 'react';
import UserWrapper from '../components/UserWrapper';
import { Avatar, Tag } from 'antd';
import { userContext } from '../context/userContext';
import { FaChevronCircleRight, FaCrown, FaSms, FaHashtag, FaTags } from 'react-icons/fa';
import { GrUpgrade } from 'react-icons/gr';
import { Link, useNavigate } from 'react-router-dom';
import { MdMessage, MdTag, MdRoute } from 'react-icons/md';
import { BiTransfer } from 'react-icons/bi';
import ProgessBar from '../components/ProgessBar';

const Setting = () => {
	const userContextData: any = useContext(userContext);
	const navigate = useNavigate();

	// Convert milliseconds to days

	return (
		<UserWrapper>
			<div className='flex justify-between md:space-x-4 items-center mx-2 md:flex-row flex-col bg-gray-100 rounded-md px-4 py-8'>
				<div className='flex items-center space-x-2 w-full '>
					<div>
						<Avatar className='bg-PrimaryBlue font-bold h-[60px] w-[60px]'>{userContextData?.userDetails?.business_name.charAt(0).toUpperCase()}</Avatar>
					</div>
					<div className='w-full'>
						<h1 className=' font-bold text-xl text-PrimaryBlack mb-1'>{userContextData?.userDetails?.business_name}</h1>
						<div>
							<ProgessBar usedSMS={userContextData?.userDetails?.sms_limit_sent} totalSMS={userContextData?.userDetails?.sms_limit} />
						</div>
					</div>
				</div>
				<button onClick={() => navigate('upgrade')} className='bg-green-500 text-PrimaryWhite py-2 px-4 inline-flex items-center font-semibold rounded'>
					Extend
					<GrUpgrade className='ml-2' />
				</button>
			</div>{' '}
			<br />
			<div className='overflow-auto  h-[60vh]'>
				<Link to={'/settings/sms-templates'}>
					<div className='p-2 flex bg-orange-50 px-4 mx-2 py-8 gap-2 items-center justify-between'>
						<div className='flex items-center gap-3'>
							<MdMessage size={20} />

							<div className='font-bold text-gray-700'>Manage SMS Templates</div>
						</div>

						<div>
							<FaChevronCircleRight />
						</div>
					</div>
				</Link>
				<Link to={'/settings/manage-groups'}>
					<div className='p-2 flex bg-orange-50 px-4 mx-2 py-8 gap-2 items-center justify-between mt-2'>
						<div className='flex items-center gap-3'>
							<FaTags size={20} />

							<div className='font-bold text-gray-700'>Manage Groups</div>
						</div>

						<div>
							<FaChevronCircleRight />
						</div>
					</div>
				</Link>{' '}
				<Link to={'/settings/manage-api'}>
					<div className='p-2 flex bg-orange-50 px-4 mx-2 py-8 gap-2 items-center justify-between mt-2'>
						<div className='flex items-center gap-3'>
							<FaHashtag size={20} />

							<div className='font-bold text-gray-700'>Manage Api Key</div>
						</div>

						<div>
							<FaChevronCircleRight />
						</div>
					</div>
				</Link>
				<Link to={'/settings/transfer'}>
					<div className='p-2 flex bg-orange-50 px-4 mx-2 py-8 gap-2 items-center justify-between mt-2'>
						<div className='flex items-center gap-3'>
							<BiTransfer size={20} />

							<div className='font-bold text-gray-700'>Transfer SMS Volume</div>
						</div>

						<div>
							<FaChevronCircleRight />
						</div>
					</div>
				</Link>
				<Link to={'/settings/manage-routes'}>
					<div className='p-2 flex bg-orange-50 px-4 mx-2 py-8 gap-2 items-center justify-between mt-2'>
						<div className='flex items-center gap-3'>
							<MdRoute size={20} />

							<div className='font-bold text-gray-700'>Manage Routes</div>
						</div>

						<div>
							<FaChevronCircleRight />
						</div>
					</div>
				</Link>
			</div>
		</UserWrapper>
	);
};

export default Setting;
