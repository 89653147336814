import React from 'react';
import Logo from '../components/Logo';

const Error = () => {
	return (
		<div className='flex flex-col md:items-center mx-4 md:mx-0 justify-center h-[100dvh] gap-4 '>
			<div className='flex justify-center'>
				<Logo />
			</div>
			<div className='bg-white border border-gray-200 shadow'>
				<div className=' p-4 lg:w-[30dvw] md:w-[50dvw] w-full'>
					<div className='w-full flex flex-col'>
						<div className='mb-6 mt-2 text-center text-PrimaryBlack'>
							<h1 className='text-2xl mb-2 font-bold'>404 Error</h1>
							<p className='text-sm'>couldn't find the page you are looking for!</p>
						</div>

						<button onClick={() => window.history.back()} className='bg-PrimaryBlack text-white font-semibold px-4 py-2 rounded-md w-full mt-2 mb-8'>
							{' '}
							Go Back
						</button>
					</div>
				</div>
			</div>
			<div className='text-[12px] text-PrimaryBlack text-center px-4'>
				For assistance, contact our support team at{' '}
				<a href='mailto:txtbond@gmail.com' className='font-bold '>
					txtbond@gmail.com.
				</a>
			</div>
		</div>
	);
};

export default Error;
