import { Progress } from 'antd';

const ProgessBar = ({ totalSMS, usedSMS }: { totalSMS: number; usedSMS: number }) => {
	const smsRemaining = totalSMS - usedSMS;

	// Calculate percentage of SMS remaining
	const percentageRemaining = 100 - (smsRemaining / totalSMS) * 100;

	return (
		<div className='!w-full'>
			<Progress percent={percentageRemaining} type='line' showInfo={false} status='exception' />
			<div className='flex justify-end text-sm font-bold text-PrimaryBlack'>
				SMS Limit: {usedSMS} / {totalSMS}
			</div>
		</div>
	);
};

export default ProgessBar;
