/* eslint-disable no-template-curly-in-string */

const getFields: any = () => {
  return [];
  let listData = [];
  for (var i = 1; i < 11; i++) {
    listData.push({
      color: "default",
      name: "field_" + i,
      // eslint-disable-next-line no-useless-concat
      value: " ${" + "field_" + i + "} ",
    });
  }
  return listData;
};

export const dynamicFields = [
  {
    color: "default",
    name: "name",
    value: " ${name} ",
  },
  {
    color: "default",
    name: "nick_name",
    value: " ${nick_name} ",
  },
  ...getFields(),
];
