import React, { useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { Spin } from 'antd';

const HandShake = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const handshakeToken = queryParams.get('token');

	useEffect(() => {
		handshakeToken && localStorage.setItem('accessToken', handshakeToken);
		navigate('/');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <Spin spinning={true}></Spin>;
};

export default HandShake;
