import jwt_decode from 'jwt-decode';

export default function decodeAccessToken(accessToken: any) {
	try {
		const decodedToken = jwt_decode(accessToken);
		return decodedToken;
	} catch (error: any) {
		if (error?.response?.status === 401) {
			// Access the status code
			window.location.href = '/';
		}
		console.error('Error decoding access token:', error);
		return null;
	}
}
