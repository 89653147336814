import { Input, Form, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import decodeAccessToken from '../utils/decodedAccessToken';
import Logo from '../components/Logo';
import notificationShow from '../utils/notificationService';

const Login = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	useEffect(() => {
		const accessToken = localStorage.getItem('accessToken');
		const token: any = decodeAccessToken(accessToken);

		if (accessToken && token !== null && token !== undefined) {
			window.location.href = token && '/dashboard';
		}
	});

	const login = async () => {
		try {
			setLoading(true);

			await form.validateFields();
			const formValues = form.getFieldsValue();
			const response = await axiosInstance.post('/users/login', {
				email: formValues.email,
				password: formValues.password,
			});

			localStorage.setItem('accessToken', response.data.accessToken);
			const accessToken = localStorage.getItem('accessToken');
			const token: any = decodeAccessToken(accessToken);

			window.location.href = token && '/dashboard';
			notificationShow({
				type: 'success',
				content: 'User logged in Successfully.',
			});

			form.resetFields();
			setLoading(false);
		} catch (error: any) {
			setError(error?.response?.data?.message);
			setLoading(false);
		}
	};

	return (
		<div className='flex flex-col md:items-center mx-4 md:mx-0 justify-center h-[100dvh] gap-4 '>
			<div className='flex justify-center '>
				<Logo />
			</div>
			<div className='bg-white border border-gray-200 shadow'>
				<div className=' p-4 lg:w-[30dvw] md:w-[50dvw] w-full'>
					<div className='w-full flex flex-col'>
						<Form autoComplete='off' size='small' layout='vertical' form={form} className='w-full '>
							<Form.Item
								required
								name='email'
								label={<div className='font-bold text-PrimaryBlack'>Email Address</div>}
								rules={[
									{
										required: true,
										message: 'Email is required.',
									},
									{
										pattern: /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
										message: 'Invalid email format.',
									},
								]}
								className='w-full'
							>
								<Input type='email' className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>
							<Form.Item
								name='password'
								label={<div className='font-bold text-PrimaryBlack'>Password</div>}
								rules={[
									{
										required: true,
										message: 'Password is required.',
									},
								]}
								className='w-full'
							>
								<Input.Password className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>
						</Form>

						<Link to='/forgot-password' className='text-PrimaryBlack text-sm underline underline-offset-4 font-semibold text-primaryColor cursor-pointer'>
							Forgot your password?
						</Link>
						<Spin spinning={loading}>
							<button onClick={login} className='bg-PrimaryOrange mt-4 text-white font-semibold px-4 py-2 rounded-md w-full my-8'>
								Login
							</button>
						</Spin>
						{error && <div className='bg-red-50 text-red-500 rounded-md text-sm text-center py-2 mb-4'>{error}</div>}

						<div className='text-center text-sm text-PrimaryBlack'>
							New to TXTBond? &nbsp;
							<Link to='/sign-up' className='cursor-pointer font-bold underline underline-offset-4'>
								Get Started
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div className='text-[12px] text-PrimaryBlack text-center px-4'>
				For assistance, contact our support team at{' '}
				<a href='mailto:txtbond@gmail.com' className='font-bold '>
					txtbond@gmail.com.
				</a>
			</div>
		</div>
	);
};

export default Login;
