import UserWrapper from '../../components/UserWrapper';
import React, { useEffect, useRef, useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { Modal, Spin, Switch, Tag } from 'antd';
import notificationShow from '../../utils/notificationService';
import { FaRegCopy, FaCheck } from 'react-icons/fa6';

const ManageAPI = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [toggle, setToggle] = useState<boolean>(true);
	const [apikey, setApiKey] = useState<any>(undefined);
	const [copied, setCopied] = useState<any>(false);
	const apiKeyRef = useRef(null);

	const getAPIkey = async () => {
		try {
			setLoading(true);

			const response = await axiosInstance.get('/settings/getAPIKEY');

			setApiKey(response?.data?.api_key);
			setCopied(false);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
		}
	};

	const generateAPIkey = async () => {
		try {
			setLoading(true);

			await axiosInstance.post('/settings/generateAPI');

			getAPIkey();

			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.message,
			});
			setLoading(false);
		}
	};

	const handleCopy = () => {
		// Access the current value of the API key
		//@ts-ignore
		const apiKeyValue = apiKeyRef.current.innerText;

		// Attempt to copy the value to the clipboard
		navigator.clipboard
			.writeText(apiKeyValue)
			.then(() => {
				setCopied(true);
			})
			.catch((error) => {
				setCopied(false);
			});
	};

	const onChange = async (checked: boolean) => {
		setToggle(checked);
			try {
			setLoading(true);

			await axiosInstance.patch(`/settings/apiStatus?status=${checked}`);

			getAPIkey();

				setLoading(false);
					notificationShow({
				type: 'success',
				content: checked?'API is Active':'API key InActive'
			});
		} catch (error: any) {
			setLoading(false);
		}
};

	const confirm = () => {
		Modal.confirm({
			centered: true,
			title: 'Are you sure you want to re-generate new api key?',
			content: 'Re-genetating new api key will make your previous key invalid.',
			okText: 'Re-Genetate API',
			cancelText: 'Cancel',
			okButtonProps: { className: 'bg-[#4096FF]' },
			onOk: generateAPIkey,
		});
	};

	useEffect(() => {
		getAPIkey();
	}, []);

	return (
		<UserWrapper>
			<Spin spinning={loading}>
				<div className='bg-yellow-50 p-2 text-center'>
					You can utilize your API key to access the features of txtbond. For detailed instructions on how to integrate and utilize this API, please refer to the documentation provided.
				</div>
				<div className='mt-4 ml-4 flex space-x-3'>
					<Switch defaultChecked onChange={onChange}  className='bg-[#8C8C8C]'/> <Tag color={toggle ? 'green':'red'}>{toggle?'Active': 'InActive'}</Tag>
				</div>
				<div className='mt-6 grid place-items-center text-center'>
					<button onClick={apikey !== 'null' ? confirm : generateAPIkey} className='bg-green-600 px-4 py-2 mt-4 text-PrimaryWhite font-semibold rounded-md'>
						{apikey && apikey !== 'null' ? 'Re-Genetate API Key' : 'Generate API key'}
					</button>
					{apikey && apikey !== 'null' && (
						<div className='bg-PrimaryWhite relative p-3 rounded-md mt-4 text-PrimaryBlack'>
							<h1 className='text-left font-bold font-xl pb-2 border-b-[1px] border-PrimaryBlack'>Your API key</h1>
							<textarea ref={apiKeyRef} value={apikey} className='hidden' readOnly />
							<p className='mt-4'>{apikey}</p>
							<button onClick={handleCopy} className='absolute right-3 top-3 flex items-center'>
								{!copied ? (
									<>
										{' '}
										<FaRegCopy className='mr-1' />
										copy
									</>
								) : (
									<span className='flex items-center text-green-500'>
										{' '}
										<FaCheck className='mr-1' />
										copied
									</span>
								)}
							</button>
						</div>
					)}
					<h1 className='mt-4'>
						Goto TxTBond{' '}
												<a href='https://txtbond.com/api-docs' className='font-bold text-blue-800 underline underline-offset-4' target='_blank'>
							API Documentation
						</a>
					</h1>
				</div>
			</Spin>
		</UserWrapper>
	);
};

export default ManageAPI;
