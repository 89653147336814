import { Input, Form, Spin } from 'antd';
import React, { useState } from 'react';
import Logo from '../components/Logo';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import notificationShow from '../utils/notificationService';

const ForgetPassword = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const [error, setError] = useState<string>('');

	const forgotPassword = async () => {
		try {
			setLoading(true);
			await form.validateFields();
			const formValues = form.getFieldsValue();
			await axiosInstance.post('/users/forgot-password', {
				email: formValues.email,
			});
			form.resetFields();
			notificationShow({
				type: 'success',
				content: `Reset OTP sent to ${formValues.email}`,
			});
			navigate(`/reset-password/${formValues.email}`);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			setError(error?.response?.data?.message);
		}
	};

	return (
		<div className='flex flex-col md:items-center mx-4 md:mx-0 justify-center h-[100dvh] gap-4 '>
			<div className='flex justify-center'>
				<Logo />
			</div>
			<div className='bg-white border border-gray-200 shadow'>
				<div className=' p-4 lg:w-[30dvw] md:w-[50dvw] w-full'>
					<div className='w-full flex flex-col'>
						<div className='mb-6 mt-2 text-center text-PrimaryBlack'>
							<h1 className='text-2xl mb-2 font-bold'>Forgot Password</h1>
							<p className='text-sm'>Please enter your email address. We'll send you a password reset OTP.</p>
						</div>

						<Form autoComplete='off' size='small' layout='vertical' form={form} className='w-full '>
							<Form.Item
								required
								name='email'
								label={<div className='font-bold text-PrimaryBlack'>Email Address</div>}
								rules={[
									{
										required: true,
										message: 'Email is required.',
									},
									{
										pattern: /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
										message: 'Invalid email format.',
									},
								]}
								className='w-full'
							>
								<Input type='email' className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>
						</Form>

						<Spin spinning={loading}>
							<button onClick={forgotPassword} className='bg-PrimaryOrange text-white font-semibold px-4 py-2 rounded-md w-full mt-2 mb-8'>
								Submit
							</button>
						</Spin>
						{error && <div className='bg-red-50 text-red-500 rounded-md text-sm text-center py-2 mb-4'>{error}</div>}

						<div className='text-center text-sm text-PrimaryBlack'>
							Already using TXTBond? &nbsp;
							<Link to='/' className='cursor-pointer font-bold underline underline-offset-4'>
								Sign in here
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className='text-[12px] text-PrimaryBlack text-center px-4'>
				For assistance, contact our support team at{' '}
				<a href='mailto:txtbond@gmail.com' className='font-bold '>
					txtbond@gmail.com.
				</a>
			</div>
		</div>
	);
};

export default ForgetPassword;
