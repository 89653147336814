import { useEffect, useState } from 'react';

import { Button, Spin } from 'antd';
import { BsCheck2Circle } from 'react-icons/bs';
import { FaTimesCircle } from 'react-icons/fa';
import axiosInstance from '../utils/axiosInstance';
import notificationShow from '../utils/notificationService';
import { Link, useLocation } from 'react-router-dom';

const VerifyPurchaseEsewa = () => {
	const [loading, setLoading] = useState(true);
	const [success, setSuccess] = useState(false);
	const [failed, setFailed] = useState(false);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const payment = queryParams.get('payment');

	console.log(payment);

	const verifyPurchaseEsewa = async (esewaResponse: string) => {
		setTimeout(async () => {
			try {
				const response: any = await axiosInstance.post('/settings/verifyEsewa', {
					esewaResponse,
				});
				setTimeout(async () => {
					window.location.replace('https://app.txtbond.com/settings');
				}, 2000);
				setFailed(false);
				setSuccess(true);
				setLoading(false);
			} catch (error: any) {
				notificationShow({
					type: 'success',
					content: error.toString(),
				});

				setFailed(true);
				setSuccess(false);
				setLoading(false);
			}
		}, 2000);
	};

	useEffect(() => {
		if (payment) {
			const esewaResponse = payment.toString().split('done?data=')[1];
			verifyPurchaseEsewa(esewaResponse);
		}
	}, [payment]);

	return (
		<>
			<div style={{ margin: 'auto', maxWidth: '600px' }}>
				{loading ? (
					<>
						<div className='text-center py-5 px-10'>
							<b className='font-bold '>Verifying your payment. This might take some time, please wait....</b>
							<br /> <br />
							<Spin size='large' />
							<br />
							<br />
							Taking too long? <br />
							<br />
							<Link to={'/'}>
								<Button className=' text-lg font-bold' size='large'>
									Return to home
								</Button>
							</Link>
						</div>
					</>
				) : (
					<></>
				)}

				{success ? (
					<>
						<div className='text-center py-5 px-10'>
							<div className='text-center flex justify-center'>
								<BsCheck2Circle size='50' className='text-[green] flex justify-center' />
							</div>
							<br />
							<h3 className='font-bold text-center'>Payment verified successfully! Redirecting you to course, please wait...</h3>
						</div>
					</>
				) : (
					<></>
				)}
				{failed ? (
					<>
						<div className='text-center py-5 px-10'>
							<div className='text-center flex justify-center'>
								<FaTimesCircle size='50' className='text-[red] flex justify-center' />
							</div>
							<br />
							<h3 className='font-bold text-center'>
								Payment verification failed. BUT WAIT, Payment might still be pending. If your account is debited but course not purchased, within 10 minutes or so, please contact us!
								<br />
							</h3>
							<br />
							<Link to={'/'}>
								<Button className=' text-lg font-bold' size='large'>
									Return to Home
								</Button>
							</Link>
						</div>
					</>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

export default VerifyPurchaseEsewa;
