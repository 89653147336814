import React, { useEffect, useState } from 'react';
import UserWrapper from '../../components/UserWrapper';
import { Drawer, Form, Input, Modal, Select, Spin, Table, TableColumnsType } from 'antd';
import notificationShow from '../../utils/notificationService';
import axiosInstance from '../../utils/axiosInstance';
import { BiTransfer } from 'react-icons/bi';
import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime');

const Transfer = () => {
	const [data, setData] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [amount, setAmount] = useState<number>(10000);
	dayjs.extend(relativeTime);

	const getTransfer = async () => {
		try {
			setLoading(true);

			const response = await axiosInstance.get(`/settings/transactions`);
			setData(response.data);
			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.error,
			});
			setLoading(false);
		}
	};

	const handleTransfer = async () => {
		try {
			setLoading(true);

			await axiosInstance.post(`/settings/transferVolume`, { email: email, amount: amount });
			setOpen(false);
			setEmail('');
			getTransfer();
			setLoading(false);
			notificationShow({
				type: 'success',
				content: 'SMS volume transferred',
			});
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.error,
			});
			setLoading(false);
		}
	};

	const confirm = () => {
		Modal.confirm({
			centered: true,
			title: 'Are you sure you want to Transfer SMS Volume?',
			content: 'Double-checking before transferring SMS volume?  Confirm to proceed!',
			okText: 'Confirm',
			cancelText: 'Cancel',
			okButtonProps: { className: 'bg-[#4096FF]' },
			onOk: handleTransfer,
		});
	};

	const columns: TableColumnsType<any> = [
		{
			title: 'Info',
			dataIndex: 'text',
			key: 'text',
			render: (text: string) => (
				<>
					<h1 className='text-PrimaryBlack font-bold'>{text ? text : '---'}</h1>
				</>
			),
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (text: string) => (
				<>
					<h1 className='text-PrimaryBlack'>{text}</h1>
				</>
			),
		},
		{
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
			render: (text: string) => <h1 className='text-PrimaryBlack'>{text}</h1>,
		},
		{
			title: 'Time',
			dataIndex: 'createdAt',
			key: 'createdAt',
			//@ts-ignore
			render: (text: string) => <h1 className='text-PrimaryBlack'>{dayjs(text).fromNow().toString()}</h1>,
		},
	];

	useEffect(() => {
		getTransfer();
	}, []);

	return (
		<UserWrapper>
			<Spin spinning={loading}>
				<div className='flex justify-between flex-col space-y-4 md:space-y-0 md:flex-row max-h-[10vh] m-4 items-center'>
					<span className=' font-bold text-[18px] text-gray-600'>Transfer History:</span>
					<button onClick={() => setOpen(true)} className='bg-green-500 text-PrimaryWhite py-2 px-4 font-semibold rounded flex items-center space-x-3'>
						<span>Transfer</span> <BiTransfer />
					</button>
				</div>

				<Table
					columns={columns}
					dataSource={data?.data}
					style={{ overflowY: 'hidden' }}
					pagination={false}
					loading={loading}
					sticky={{ offsetHeader: 0 }}
					scroll={{ x: '1500', y: '70vh' }}
					rowKey={'history'}
				/>
			</Spin>
			<Drawer
				destroyOnClose={true}
				title={`Transfer SMS Volume`}
				placement={'right'}
				closable={true}
				onClose={() => {
					setOpen(false);
					setEmail('');
				}}
				open={open}
				key={'transfersmsvolume'}
			>
				<Spin spinning={loading}>
					<Form.Item required name='email' label={<div className='font-bold text-PrimaryBlack'>Email</div>} className='w-full mb-2' />
					<Input required type='email' defaultValue={email} onChange={(e) => setEmail(e.target.value)} className='border border-gray-300 rounded-md w-full py-2 px-4' />
					<Form.Item required name='email' label={<div className='font-bold text-PrimaryBlack'>Amount</div>} className='w-full mb-2 mt-4' />
					<Select
						size='large'
						className='block'
						placeholder='Select Amount'
						defaultValue={amount}
						onChange={(value) => setAmount(value)}
						options={[
							{ value: 10000, label: '10,000' },
							{ value: 25000, label: '25,000' },
							{ value: 50000, label: '50,000' },
						]}
					/>
					<button
						onClick={() => {
							if (email === '' || amount === undefined || email === null || amount === null || email === undefined) {
								setError(true);
							} else {
								confirm();
								setError(false);
							}
						}}
						className='bg-PrimaryOrange mt-10 text-PrimaryWhite py-2 px-4 font-semibold rounded'
					>
						Transfer SMS Volume
					</button>
					{error && <div className='bg-red-100 mt-4 rounded-md p-4'>Please enter valid email and amount</div>}
				</Spin>
			</Drawer>
		</UserWrapper>
	);
};

export default Transfer;
