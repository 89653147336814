import React, { useState } from "react";
import axiosInstance from "../utils/axiosInstance";

interface IUserContext {
  userDetails: any[];
  getConfig: any;

}

export const userContext = React.createContext<IUserContext>({
  userDetails: [],
  getConfig: () => {},

});

const UserContextFunction = ({ children }: any) => {


  const [userDetails, setUserDetails] = useState<any>({});
 
 


 
  const getConfig = async () => {
    if (localStorage.getItem("accessToken")) {
      try {
        const response = await axiosInstance.get("users/get-config");
        setUserDetails(response.data.data);
      } catch (error: any) {
        console.error(error);
      }
    }
  };



  

  return (
    <userContext.Provider value={{ userDetails, getConfig  }}>
      {children}
    </userContext.Provider>
  );
};

export default UserContextFunction;
