import React, { useContext, useEffect, useState } from 'react';
import UserWrapper from '../../components/UserWrapper';
import axiosInstance from '../../utils/axiosInstance';
import notificationShow from '../../utils/notificationService';
import { Drawer, Form, Input, Modal, Pagination, Popconfirm, Popover, Select, Spin, Table, TableColumnsType, Tag } from 'antd';
import { FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { SmSSenderContext } from '../../context/smsSenderContext';

const Contacts = () => {
	const smsSenderContext = useContext(SmSSenderContext);

	const [form] = Form.useForm();
	const [editForm] = Form.useForm();
	const [addGroup] = Form.useForm();
	const [bulkContacts] = Form.useForm();
	const [selectTableRowKeys, setSelectTableRowKeys] = useState<React.Key[]>([]);
	const [groupsItem, setGroupsItem] = useState<React.Key[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [groupId, setGroupId] = useState<string>('');
	const [updateBulkContacts, setUpdateBulkContacts] = useState<boolean>(false);
	const [openAddGroupModel, setOpenAddGroupModel] = useState<boolean>(false);
	const [contactsData, setContactsData] = useState<any>([]);
	const [openUserPopOver, setOpenUserPopOver] = useState<boolean>(false);
	// const [openImportContact, setOpenImportContact] = useState<boolean>(false);
	const [editContactDrawerOpen, setEditContactDrawerOpen] = useState<boolean>(false);
	const [singleUserDetails, setSingleUserDetails] = useState<any>();
	useState<boolean>(false);
	const [addContactDrawerOpen, setAddContactDrawerOpen] = useState<boolean>(false);
	const [selectedGroupColor, setSelectedGroupColor] = useState<string>('');
	const [search, setSearch] = useState<any>('');
	const [paginationPage, setPaginationPage] = useState(1);
	const [paginationLimit, setPaginationLimit] = useState(25);
	const [totalRecords, setTotalRecords] = useState(0);

	const customColor = ['magenta', 'red', 'volcano', 'orange', 'gold', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple'];

	interface DataType {
		key: React.Key;
		name: string;
		number: number;
		nick_name: string;
		groups: [];
	}

	const getContacts = async (pagination_page: number, pagination_limit: number, search: string, group: string) => {
		setContactsData([]);
		try {
			setLoading(true);

			const response = await axiosInstance.get(`/contacts?pagination_page=${pagination_page}&pagination_limit=${pagination_limit}${search && `&search=${search}`}${group && `&groups=${group}`}`);
			setContactsData(response.data.data);
			setTotalRecords(response.data.total_records_all_no_filter);
			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.error,
			});
			setLoading(false);
		}
	};

	// 		const handleSearch = async (search:string) => {
	// 		try {
	// 			setLoading(true);
	// console.log(search);

	// 			const response = await axiosInstance.get(`/contacts?pagination_page=1&pagination_limit=25&search=${search}`);
	// 			setContactsData(response.data);
	// 			setTotalRecords(response.data.total_records);
	// 			setLoading(false);
	// 		} catch (error: any) {
	// 			notificationShow({
	// 				type: 'error',
	// 				content: error?.response?.data?.error,
	// 			});
	// 			setLoading(false);
	// 		}
	// 	};

	const userPopOverContent = () => {
		return (
			<div className='flex flex-col space-y-2'>
				<button
					onClick={() => {
						setEditContactDrawerOpen(true);
					}}
					className='bg-PrimaryBlue text-PrimaryWhite py-2 px-4 font-semibold rounded'
				>
					Edit
				</button>
				<button
					onClick={() => {
						editForm.resetFields();
						smsSenderContext.showMessageDrawer({
							_id: singleUserDetails._id,
						});
					}}
					className='bg-red-500 text-PrimaryWhite py-2 px-4 font-semibold rounded'
				>
					Send SMS
				</button>
			</div>
		);
	};

	const getGroups = async () => {
		try {
			setLoading(true);

			const response = await axiosInstance.get('/groups?showCount=true');
			setGroupsItem(response.data.data);
			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.error,
			});
			setLoading(false);
		}
	};

	const handleContactAdd = async () => {
		try {
			setLoading(true);

			await form.validateFields();
			const formValues = form.getFieldsValue();

			await axiosInstance.post('/contacts', {
				number: formValues.number,
				name: formValues.name,
				email: formValues.email,
				nick_name: formValues.nick_name,
				groups: formValues?.groups?.map((item: any) => item),
			});

			notificationShow({
				type: 'success',
				content: 'Contact added Successfully.',
			});
			form.resetFields();
			getContacts(paginationPage, paginationLimit, search, groupId);
			getGroups();
			setLoading(false);
			setAddContactDrawerOpen(false);
		} catch (error: any) {
			if (error?.errorFields?.length > 0) {
				return;
			} else {
				notificationShow({
					type: 'error',
					content: error?.response?.data.message,
				});
			}

			setLoading(false);
		}
	};

	const handleContactDelete = async () => {
		try {
			setLoading(true);

			const contactsID = selectTableRowKeys.map((item: any) => item._id);
			await axiosInstance.post('/contacts/delete', {
				contact_ids: contactsID,
			});

			notificationShow({
				type: 'success',
				content: 'Contact deleted Successfully.',
			});
			setSelectTableRowKeys([]);
			getContacts(paginationPage, paginationLimit, search, groupId);

			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data.message,
			});
			setLoading(false);
		}
	};

	const handleAddGroup = async () => {
		try {
			setLoading(true);

			await form.validateFields();
			const formValues = addGroup.getFieldsValue();

			if (form.getFieldsError().length > 0) {
				return;
			}

			await axiosInstance.post('/groups', {
				group_name: formValues.group_name,
				color: selectedGroupColor,
			});

			notificationShow({
				type: 'success',
				content: 'Group added Successfully.',
			});
			window.location.reload();
			addGroup.resetFields();
			getGroups();
			setLoading(false);
			setSelectedGroupColor('');
			setOpenAddGroupModel(false);
		} catch (e: any) {
			if (e && e.response && e.response.data && e.response.data.message) {
				notificationShow({
					type: 'error',
					content: e?.response?.data.message,
				});
			} else {
				notificationShow({
					type: 'error',
					content: 'Connection Failed',
				});
			}

			setLoading(false);
		}
	};

	const addGroupModel = () => {
		return (
			<Modal
				title='Add Group'
				okText={'Add Group'}
				okButtonProps={{
					className: '!bg-PrimaryBlue !text-PrimaryWhite font-semibold',
				}}
				centered
				open={openAddGroupModel}
				onOk={handleAddGroup}
				onCancel={() => {
					setOpenAddGroupModel(false);
					addGroup.resetFields();
					setSelectedGroupColor('');
				}}
			>
				<Form autoComplete='off' size='small' layout='vertical' form={addGroup} className='w-full'>
					<Form.Item
						required
						name='group_name'
						label={<div className='font-bold text-PrimaryBlack'>Group Name</div>}
						rules={[
							{
								required: true,
								message: 'group name is required.',
							},
						]}
						className='w-full'
					>
						<Input type='text' className='border border-gray-300 rounded-md w-full py-2 px-4' />
					</Form.Item>
					<Form.Item
						required
						name='color'
						label={<div className='font-bold text-PrimaryBlack'>Choose Color</div>}
						rules={[
							{
								required: true,
								message: 'color is required.',
							},
						]}
						className='w-full'
					>
						<div className='flex flex-wrap'>
							{customColor.map((color, i) => (
								<Tag key={i} color={color} className={`cursor-pointer m-1 ${selectedGroupColor === color && 'font-extrabold'}`} onClick={() => setSelectedGroupColor(color)}>
									{color}
								</Tag>
							))}
						</div>
					</Form.Item>
				</Form>
			</Modal>
		);
	};

	const editContactDrawer = () => {
		return (
			<Drawer
				size='large'
				destroyOnClose={true}
				title={`${singleUserDetails?.name}`}
				placement={'right'}
				closable={true}
				onClose={() => {
					setEditContactDrawerOpen(!editContactDrawerOpen);
				}}
				open={editContactDrawerOpen}
				key={'editcontact'}
				footer={
					<>
						<button onClick={handleEditContact} className='bg-PrimaryOrange text-PrimaryWhite py-2 px-4 font-semibold rounded'>
							Update Contact
						</button>
					</>
				}
			>
				<Spin spinning={loading}>
					<Form autoComplete='off' size='small' layout='vertical' form={editForm} className='w-full overflow-y-auto h-[80vh]'>
						<Form.Item
							required
							name='name'
							label={<div className='font-bold text-PrimaryBlack'>Name</div>}
							rules={[
								{
									required: true,
									message: 'Name is required.',
								},
							]}
							className='w-full'
						>
							<Input type='text' className='border border-gray-300 rounded-md w-full py-2 px-4' />
						</Form.Item>
						<Form.Item
							required
							name='nick_name'
							label={<div className='font-bold text-PrimaryBlack'>Nickname</div>}
							rules={[
								{
									required: true,
									message: 'Nickname is required.',
								},
							]}
							className='w-full'
						>
							<Input type='text' className='border border-gray-300 rounded-md w-full py-2 px-4' />
						</Form.Item>
						<Form.Item
							required
							name='number'
							label={<div className='font-bold text-PrimaryBlack'>Number</div>}
							rules={[
								{
									required: true,
									message: 'Number is required.',
								},
							]}
							className='w-full'
						>
							<Input type='number' className='border border-gray-300 rounded-md w-full py-2 px-4' />
						</Form.Item>
						<Form.Item name='email' label={<div className='font-bold text-PrimaryBlack'>Email</div>} className='w-full'>
							<Input type='email' className='border border-gray-300 rounded-md w-full py-2 px-4' />
						</Form.Item>
						<Form.Item
							name='groups'
							label={
								<div className='flex space-x-4'>
									<div className='font-bold text-PrimaryBlack'>Groups</div>
									{/* <div onClick={() => setOpenAddGroupModel(true)} className='cursor-pointer text-gray-500 font-medium'>
										+ Create new group
									</div> */}
								</div>
							}
							className='w-full'
						>
							<Select
								mode='multiple'
								size='large'
								allowClear
								placeholder='Select groups'
								options={groupsItem.map((item: any) => {
									return { label: item.group_name, value: item?._id };
								})}
							/>
						</Form.Item>
						{/* {Array.from({ length: 10 }).map((_, index) => (
							<Form.Item
								key={index}
								name={`field_${index + 1}`} // Use a unique name for each Form.Item, e.g., 'number0', 'number1', ...
								label={<div className='font-bold text-PrimaryBlack'>Field_{index + 1}</div>}
								className='w-full'
							>
								<Input type='text' className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>
						))} */}
					</Form>
				</Spin>
			</Drawer>
		);
	};

	const handleBulkEditContact = async () => {
		try {
			setLoading(true);

			await bulkContacts.validateFields();
			const formValues = bulkContacts.getFieldsValue();

			const allIDs = selectTableRowKeys.map((item: any) => {
				return item._id.toString();
			});

			await axiosInstance.patch('/contacts', {
				contact_id: allIDs,
				groups: formValues?.groups?.map((item: any) => (typeof item === 'string' ? item : item.value)),
				field_1: formValues.field_1,
				field_2: formValues.field_2,
				field_3: formValues.field_3,
				field_4: formValues.field_4,
				field_5: formValues.field_5,
				field_6: formValues.field_6,
				field_7: formValues.field_7,
				field_8: formValues.field_8,
				field_9: formValues.field_9,
				field_10: formValues.field_10,
			});

			notificationShow({
				type: 'success',
				content: 'Contact updated Successfully.',
			});
			bulkContacts.resetFields();
			getContacts(paginationPage, paginationLimit, search, groupId);
			getGroups();

			setLoading(false);
			setUpdateBulkContacts(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data.message,
			});
			setLoading(false);
		}
	};

	const handleEditContact = async () => {
		try {
			setLoading(true);

			await editForm.validateFields();
			const formValues = editForm.getFieldsValue();

			await axiosInstance.patch('/contacts', {
				contact_id: [singleUserDetails._id],
				number: formValues.number,
				name: formValues.name,
				email: formValues.email,
				nick_name: formValues.nick_name,
				groups: formValues?.groups?.map((item: any) => (typeof item === 'string' ? item : item.value)),
				field_1: formValues.field_1,
				field_2: formValues.field_2,
				field_3: formValues.field_3,
				field_4: formValues.field_4,
				field_5: formValues.field_5,
				field_6: formValues.field_6,
				field_7: formValues.field_7,
				field_8: formValues.field_8,
				field_9: formValues.field_9,
				field_10: formValues.field_10,
			});

			notificationShow({
				type: 'success',
				content: 'Contact updated Successfully.',
			});
			editForm.resetFields();
			getContacts(paginationPage, paginationLimit, search, groupId);
			getGroups();

			setLoading(false);
			setEditContactDrawerOpen(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data.message,
			});
			setLoading(false);
		}
	};

	const getFields: any = () => {
		let listData: any[] = [];
		for (var i = 1; i < 11; i++) {
			//   listData.push({
			//     title: `field_${i}`,
			//     dataIndex: `field_${i}`,
			//     key: `field_${i}`,
			//     width: 150,
			//     render: (text: string) => (
			//       <h1 className="text-PrimaryBlack ">{text}</h1>
			//     ),
			//   });
		}
		return listData;
	};

	const columns: TableColumnsType<DataType> = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			fixed: 'left',
			width: 250,
			render: (text: string, record: any) => (
				<Popover
					placement='bottomLeft'
					trigger='click'
					onOpenChange={() => {
						setOpenUserPopOver(!openUserPopOver);
					}}
					content={userPopOverContent}
				>
					<h1
						className='text-PrimaryBlack font-bold  cursor-pointer'
						onClick={() => {
							setSingleUserDetails(record);

							editForm.setFieldsValue({
								name: record?.name,
								nick_name: record?.nick_name,
								number: record?.number,
								email: record?.email,
								groups: record?.groups.map((item: any) => ({
									label: item.group_name,
									value: item._id,
								})),
								field_1: record.field_1,
								field_2: record.field_2,
								field_3: record.field_3,
								field_4: record.field_4,
								field_5: record.field_5,
								field_6: record.field_6,
								field_7: record.field_7,
								field_8: record.field_8,
								field_9: record.field_9,
								field_10: record.field_10,
							});
						}}
					>
						{text}
					</h1>
				</Popover>
			),
		},
		{
			title: 'Nick Name',
			dataIndex: 'nick_name',
			key: 'nick_name',
			width: 150,
			render: (text: string) => <h1 className='text-PrimaryBlack'>{text}</h1>,
		},
		{
			title: 'Number',
			dataIndex: 'number',
			key: 'number',
			width: 150,
			render: (text: string) => <h1 className='text-PrimaryBlack '>{text}</h1>,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			width: 150,
			render: (text: string) => <h1 className='text-PrimaryBlack '>{text}</h1>,
		},

		{
			title: 'Groups',
			dataIndex: 'groups',
			key: 'groups',
			width: 150,
			render: (groupsData: []) =>
				groupsData?.map((item: any, i) => (
					<Tag color={item.color} className='m-[2px]'>
						{item?.group_name}
					</Tag>
				)),
		},
		...getFields(),
	];

	const onSelectChange = (_: any, selectedRows: any) => {
		setSelectTableRowKeys(selectedRows);
	};

	const rowSelection = {
		selectTableRowKeys,
		onChange: onSelectChange,
	};

	useEffect(() => {
		getContacts(1, 25, search, groupId);
		getGroups();
	}, []);
	const hasSelected = selectTableRowKeys.length > 0;

	useEffect(() => {
		getContacts(paginationPage, paginationLimit, search, groupId);
	}, [paginationPage, paginationLimit]);

	useEffect(() => {
		const timerId = setTimeout(() => {
			getContacts(1, 25, search, groupId);
		}, 1000);

		return () => {
			clearTimeout(timerId);
		};
	}, [search]);

	return (
		<UserWrapper>
			{hasSelected ? (
				<div className='flex justify-end flex-col md:space-y-0 md:flex-row m-4 space-y-4 space-x-2'>
					<button
						onClick={() => {
							smsSenderContext.showMessageDrawer({
								_id: { $in: selectTableRowKeys.map((el: any) => el._id) },
							});
						}}
						className='bg-orange-500 text-PrimaryWhite py-2 px-4 font-semibold rounded'
					>
						Send SMS
					</button>
					<button onClick={() => setUpdateBulkContacts(true)} className='bg-gray-500 text-PrimaryWhite py-2 px-4 font-semibold rounded'>
						Bulk Update
					</button>

					<Popconfirm
						title='Delete Contact'
						placement='bottomRight'
						description='Are you sure to delete contact?'
						okText='Yes'
						cancelText='No'
						okButtonProps={{ className: 'bg-red-400' }}
						onConfirm={handleContactDelete}
					>
						<button className='bg-red-600 text-PrimaryWhite py-2 px-4 font-semibold rounded'>
							<FaTrash />
						</button>
					</Popconfirm>
					<Drawer
						size='large'
						destroyOnClose={true}
						title={`Bulk Update`}
						placement={'right'}
						closable={true}
						onClose={() => {
							setUpdateBulkContacts(!updateBulkContacts);
						}}
						open={updateBulkContacts}
						key={'editcontact'}
						footer={
							<>
								<div className='flex space-x-2 '>
									<button
										onClick={() => {
											bulkContacts.resetFields();
											setUpdateBulkContacts(false);
										}}
										className='bg-gray-400 text-PrimaryWhite py-2 px-4 font-semibold rounded'
									>
										Cancel
									</button>
									<button onClick={handleBulkEditContact} className='bg-PrimaryOrange text-PrimaryWhite py-2 px-4 font-semibold rounded'>
										Bulk Update
									</button>
								</div>
							</>
						}
					>
						<Spin spinning={loading}>
							<Form autoComplete='off' size='small' layout='vertical' form={bulkContacts} className='w-full overflow-y-auto h-[80vh]'>
								<Form.Item
									name='groups'
									label={
										<div className='flex space-x-4'>
											<div className='font-bold text-PrimaryBlack'>Groups</div>
										</div>
									}
									className='w-full'
								>
									<Select
										mode='multiple'
										size='large'
										allowClear
										placeholder='Select groups'
										options={groupsItem.map((item: any) => {
											return { label: item.group_name, value: item?._id };
										})}
									/>
								</Form.Item>
								{/* {Array.from({ length: 10 }).map((_, index) => (
                  <Form.Item
                    key={index}
                    name={`field_${index + 1}`} // Use a unique name for each Form.Item, e.g., 'number0', 'number1', ...
                    label={
                      <div className="font-bold text-PrimaryBlack">
                        Field_{index + 1}
                      </div>
                    }
                    className="w-full"
                  >
                    <Input
                      type="text"
                      className="border border-gray-300 rounded-md w-full py-2 px-4"
                    />
                  </Form.Item>
                ))} */}
							</Form>
						</Spin>
					</Drawer>
				</div>
			) : (
				<div className='flex justify-end flex-col space-y-4 md:space-y-0 md:flex-row max-h-[10vh] m-4 items-center'>
					<Drawer
						size='large'
						placement={'right'}
						className='!bg-PrimaryWhite'
						title={'Add contact'}
						closable={true}
						onClose={() => {
							setAddContactDrawerOpen(false);
							form.resetFields();
						}}
						open={addContactDrawerOpen}
						key={'addcontact'}
					>
						<Form autoComplete='off' size='small' layout='vertical' form={form} className='w-full '>
							<Form.Item
								required
								name='name'
								label={<div className='font-bold text-PrimaryBlack'>Name</div>}
								rules={[
									{
										required: true,
										message: 'Name is required.',
									},
								]}
								className='w-full'
							>
								<Input type='text' className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>
							<Form.Item
								required
								name='nick_name'
								label={<div className='font-bold text-PrimaryBlack'>Nickname</div>}
								rules={[
									{
										required: true,
										message: 'Nickname is required.',
									},
								]}
								className='w-full'
							>
								<Input type='text' className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>
							<Form.Item
								required
								name='number'
								label={<div className='font-bold text-PrimaryBlack'>Number</div>}
								rules={[
									{
										required: true,
										message: 'Number is required.',
									},
								]}
								className='w-full'
							>
								<Input type='text' className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>
							<Form.Item name='email' label={<div className='font-bold text-PrimaryBlack'>Email</div>} className='w-full'>
								<Input type='email' className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>
							<Form.Item
								name='groups'
								label={
									<div className='flex space-x-4'>
										<div className='font-bold text-PrimaryBlack'>Groups</div>
										{/* <div onClick={() => setOpenAddGroupModel(true)} className='cursor-pointer text-gray-500 font-medium'>
											+ Create new group
										</div> */}
									</div>
								}
								className='w-full'
							>
								<Select
									mode='multiple'
									size='large'
									allowClear
									placeholder='Select groups'
									options={groupsItem.map((item: any) => {
										return { label: item.group_name, value: item?._id };
									})}
								/>
							</Form.Item>
						</Form>

						<button onClick={handleContactAdd} className='bg-PrimaryOrange text-PrimaryWhite py-2 px-4 font-semibold rounded mt-4'>
							Add Contact
						</button>
					</Drawer>

					<div className='flex gap-2'>
						{/* <div>
							<input
								type='search'
								placeholder='Search...'
								className='border-2 p-2 rounded-lg outline-none focus:border-blue-500 active:bg-none'
								onChange={(e) => {
									setSearch(e.target.value);
								}}
							/>
						</div> */}
						<button onClick={() => setAddContactDrawerOpen(true)} className='bg-PrimaryOrange text-PrimaryWhite py-2 px-4 font-semibold rounded'>
							+
						</button>
						<Link to='/contacts/import' className='bg-PrimaryBlack text-PrimaryWhite py-2 px-4 font-semibold rounded'>
							Import
						</Link>
					</div>
				</div>
			)}
			<div className='md:grid grid-cols-12 h-[70vh] gap-2'>
				<div className='col-span-3 flex flex-col space-y-4  relative overflow-y-auto'>
					<div className='sticky top-0 w-full'>
						<div>
							<input
								type='search'
								placeholder='Search...'
								className='border-2 p-2 w-full rounded-lg outline-none focus:border-blue-500 active:bg-none'
								onChange={(e) => {
									setSearch(e.target.value);
								}}
							/>
						</div>
						<div onClick={() => setOpenAddGroupModel(true)} className='bg-PrimaryWhite px-4 py-2 mt-4 text-center  rounded-md cursor-pointer text-gray-500 font-medium'>
							+ Add new group
						</div>
					</div>
					<div
						onClick={() => {
							setGroupId('');
							getContacts(paginationPage, paginationLimit, search, '');
						}}
						className='flex cursor-pointer hover:bg-PrimaryWhite font-semibold rounded-md justify-between items-center  px-4 py-2 text-gray-600'
					>
						<h1>All Contacts:</h1>
						<p className=' rounded-md py-1 px-2'>{totalRecords}</p>
					</div>
					{groupsItem?.length !== 0 &&
						groupsItem.map((item: any, i) => (
							<>
								<div
									key={i}
									onClick={() => {
										setGroupId(item._id);
										getContacts(paginationPage, paginationLimit, search, item._id);
									}}
									className='flex cursor-pointer hover:bg-PrimaryWhite font-semibold rounded-md justify-between items-center  px-4 py-2 text-gray-600'
								>
									<h1>{item.group_name}:</h1>
									<p className=' rounded-md py-1 px-2'>{item.count}</p>
								</div>
							</>
						))}
				</div>
				<div className='col-span-9'>
					<Table
						style={{ overflowY: 'hidden' }}
						pagination={false}
						columns={columns}
						rowSelection={rowSelection}
						dataSource={contactsData}
						rowKey={'number'}
						loading={loading}
						sticky={{ offsetHeader: 0 }}
						scroll={{ x: '1500', y: '63vh' }}
					/>
				</div>
			</div>

			<div className=' h-[5vh] flex justify-between mt-5'>
				<div>
					Records per page:
					<Select
						className='ml-2'
						onChange={(value) => {
							setPaginationLimit(value);
						}}
						defaultValue={25}
						options={[
							{
								value: '5',
								label: 5,
							},

							{
								value: '25',
								label: 25,
							},
							{
								value: '50',
								label: 50,
							},
							{
								value: '100',
								label: 100,
							},
						]}
					></Select>
				</div>
				<div>
					<Pagination
						defaultCurrent={1}
						showSizeChanger={false}
						total={totalRecords}
						pageSize={paginationLimit}
						responsive={true}
						onChange={(value) => {
							setPaginationPage(value);
						}}
					/>
				</div>
			</div>
			{editContactDrawer()}
			{addGroupModel()}
		</UserWrapper>
	);
};

export default Contacts;
