import { Spin, Table, TableColumnsType } from 'antd';
import UserWrapper from '../../components/UserWrapper';
import { useEffect, useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import notificationShow from '../../utils/notificationService';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
var relativeTime = require('dayjs/plugin/relativeTime');

interface DataType {
	key: React.Key;
	message: string;
	number: number;
	status: boolean;
}

const SMSQueue = () => {
	const navigate = useNavigate();
	const [data, setData] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(false);
	dayjs.extend(relativeTime);

	const handleQueue = async () => {
		try {
			setLoading(true);

			const response = await axiosInstance.get('/sms/queue');
			setData(response.data);

			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.error,
			});
			setLoading(false);
		}
	};

	const columns: TableColumnsType<DataType> = [
		{
			title: 'Contact Name',
			dataIndex: 'contact_name',
			key: 'contact_name',
			render: (text: string) => (
				<>
					<h1 className='text-PrimaryBlack font-bold'>{text ? text : '---'}</h1>
				</>
			),
		},
		{
			title: 'Number',
			dataIndex: 'number',
			key: 'number',
			render: (text: string) => (
				<>
					<h1 className='text-PrimaryBlack'>{text}</h1>
				</>
			),
		},
		{
			title: 'Message',
			dataIndex: 'message',
			key: 'message',
			render: (text: string) => <h1 className='text-PrimaryBlack'>{text}</h1>,
		},
		{
			title: 'Added In Queue',
			dataIndex: 'queue',
			key: 'queue',
			//@ts-ignore
			render: (text: string, record: any) => <h1 className='text-PrimaryBlack '>{dayjs(record?.timestamp).fromNow().toString()}</h1>,
		},
	];

	useEffect(() => {
		handleQueue();
	}, []);

	return (
		<UserWrapper>
			<div className='flex justify-between flex-col space-y-4 md:space-y-0 md:flex-row max-h-[10vh] m-4 items-center'>
				<span className=' font-bold text-[18px] text-gray-600'>SMS Queue:</span>
				<button onClick={() => navigate('/sms-history')} className='bg-PrimaryBlack text-PrimaryWhite py-2 px-4 font-semibold rounded'>
					SMS History
				</button>
			</div>

			<div className='mb-2 bg-yellow-200 px-4 py-2 rounded-md text-PrimaryBlack text-sm'>
				<span className='font-bold'>Note: </span>SMS in queue cannot be modified nor deleted. If SMS is at queue for a longer time then there is some issue with txtBond Android Client. Please check if
				its running on your device
			</div>
			<Spin spinning={loading}>
				<Table columns={columns} dataSource={data?.allMessages} rowKey={'queue'} scroll={{ x: true }} />
			</Spin>
		</UserWrapper>
	);
};

export default SMSQueue;
