import React, { useEffect, useState } from 'react';
import UserWrapper from '../../components/UserWrapper';
import { Drawer, Popconfirm, Spin, Form, Input, Tag } from 'antd';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import axiosInstance from '../../utils/axiosInstance';
import notificationShow from '../../utils/notificationService';
import TextArea from 'antd/es/input/TextArea';
import { dynamicFields } from '../config';

const MessageTemplate = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [editTemplate, setEditTemplate] = useState<boolean>(false);
	const [templateName, setTemplateName] = useState<string>('');
	const [templateID, setTemplateID] = useState<any>(undefined);
	const [message, setMessage] = useState<string>('');
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);
	const [templatesData, setTemplatesData] = useState<any>([]);

	const handleTemplates = async () => {
		try {
			setLoading(true);

			const response = await axiosInstance.get('/templates');
			setTemplatesData(response.data);

			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.error,
			});
			setLoading(false);
		}
	};

	const handleTemplatesEntry = async (id: any) => {
		try {
			setLoading(true);

			!editTemplate
				? await axiosInstance.post('/templates', { template_name: templateName, message: message })
				: await axiosInstance.patch('/templates', { template_id: id, template_name: templateName, message: message });

			setLoading(false);
			setEditTemplate(false);
			setOpenDrawer(false);
			setTemplateName('');
			setMessage('');
			setTemplateID(undefined);
			handleTemplates();
			notificationShow({
				type: 'success',
				content: 'template added successfully.',
			});
			window.location.reload();
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.error,
			});
			setLoading(false);
		}
	};

	const handleTemplateDelete = async (id: string) => {
		try {
			setLoading(true);

			await axiosInstance.delete(`/templates/${id}`);

			notificationShow({
				type: 'success',
				content: 'Template deleted Successfully.',
			});

			handleTemplates();
			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.message,
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		handleTemplates();
	}, []);

	return (
		<UserWrapper>
			<Spin spinning={loading}>
				<div className='flex justify-between flex-col space-y-4 md:space-y-0 md:flex-row max-h-[10vh] m-4 items-center'>
					<span className=' font-bold text-[18px] text-gray-600'>SMS Template:</span>
					<button onClick={() => setOpenDrawer(true)} className='bg-PrimaryOrange text-PrimaryWhite py-2 px-4 font-semibold rounded'>
						Add Template
					</button>
				</div>
				<Drawer
					size='large'
					placement={'right'}
					className='!bg-PrimaryWhite'
					title={editTemplate ? 'Update Template' : 'Add Template'}
					closable={true}
					onClose={() => {
						setOpenDrawer(false);
						setMessage('');
						setTemplateName('');
						setEditTemplate(false);
						setTemplateID(undefined);
					}}
					open={openDrawer}
					key={'manageTempalte'}
				>
					<Form.Item required name='template_name' label={<div className='font-bold text-PrimaryBlack'>Template Name</div>} className='w-full !m-0' />
					<Input
						value={templateName}
						onChange={(e) => {
							setTemplateName(e.target.value);
						}}
					/>
					<Form.Item required name='Message' label={<div className='font-bold text-PrimaryBlack'>Message</div>} className='w-full !m-0' />
					<TextArea
						rows={4}
						onChange={(e) => {
							setMessage(e.target.value);
						}}
						value={message}
					/>
					<Form.Item required name='dynamic_fields' label={<div className='font-bold text-PrimaryBlack'>Dynamic Fields</div>} className='w-full !m-0' />
					<div className='flex flex-wrap'>
						{dynamicFields.map((item: any, i) => (
							<Tag key={i} color={item.color} className='m-1 cursor-pointer' onClick={() => setMessage(message + item.value)}>
								{item.name}
							</Tag>
						))}
					</div>
					<button onClick={() => handleTemplatesEntry(templateID)} className='bg-PrimaryOrange text-PrimaryWhite py-2 px-4 font-semibold rounded mt-4'>
						{editTemplate ? 'Update' : 'Add'} Template
					</button>
				</Drawer>

				<div className=' h-[75vh] overflow-y-auto'>
					{templatesData?.data?.length !== 0 ? (
						templatesData?.data?.map((template: any) => (
							<div className='p-4 bg-gray-50 text-PrimaryBlack max-w-[800px] mb-4 flex justify-between rounded-md'>
								<div className='text-justify mr-2'>
									<div className='font-bold'>
										Template Name: <span>{template?.template_name}</span>
									</div>
									<div className='font-bold my-1'>Message:</div>
									<p>{template?.message}</p>
								</div>
								<div className=' min-w-[80px]'>
									<button
										onClick={() => {
											setOpenDrawer(true);
											setEditTemplate(true);
											setTemplateName(template.template_name);
											setMessage(template.message);
											setTemplateID(template._id);
										}}
										className='bg-green-600 mr-2 p-2 rounded-md text-xl text-PrimaryWhite'
									>
										<FaEdit />
									</button>
									<Popconfirm
										title='Delete Template'
										placement='bottomRight'
										description='Are you sure to delete template?'
										okText='Yes'
										cancelText='No'
										okButtonProps={{ className: 'bg-red-400' }}
										onConfirm={() => handleTemplateDelete(template._id)}
									>
										<button className='bg-red-600 p-2 rounded-md text-xl text-PrimaryWhite'>
											<MdDelete />
										</button>
									</Popconfirm>
								</div>
							</div>
						))
					) : (
						<div className='text-center font-semibold bg-gray-50 py-4'>
							<h1>There is no any SMS Template yet!</h1>
						</div>
					)}
				</div>
			</Spin>
		</UserWrapper>
	);
};

export default MessageTemplate;
