import { Drawer, Empty, Form, Input, Modal, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { MdDelete, MdRoute } from 'react-icons/md';
import { RiLockPasswordFill } from 'react-icons/ri';
import UserWrapper from '../../components/UserWrapper';
import axiosInstance from '../../utils/axiosInstance';
import notificationShow from '../../utils/notificationService';

const ManageRoutes = () => {
	const [data, setData] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [edit, setEdit] = useState<boolean>(false);
	const [route, setRoute] = useState<string>('');
	const [id, setId] = useState<string>('');
	const [otp, setOTP] = useState<number>();
	const [OTPModle, setOTPModle] = useState<boolean>(false);

	const getRoutes = async () => {
		try {
			setLoading(true);

			const response = await axiosInstance.get(`/settings/route`);
			setData(response.data);
			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.error,
			});
			setLoading(false);
		}
	};

	const addRoutes = async () => {
		try {
			setLoading(true);

			await axiosInstance.post(`/settings/route`, {
				name: route,
			});
			getRoutes();
			setLoading(false);
			setOpen(false);
			setRoute('');
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.error,
			});
			setLoading(false);
		}
	};

	const generateOTP = async (id: string) => {
		try {
			setLoading(true);

			const res = await axiosInstance.post(`/settings/route/generateOTPForRoute`, {
				route_id: id,
			});
			setOTP(res?.data?.otp);
			setOTPModle(true);
			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.error,
			});
			setLoading(false);
		}
	};

	const deleteRoute = async (id: string) => {
		try {
			setLoading(true);

			await axiosInstance.delete(`/settings/route/${id}`);

			getRoutes();
			setId('');
			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.error,
			});
			setLoading(false);
		}
	};

	const editRoutes = async () => {
		try {
			setLoading(true);

			await axiosInstance.patch(`/settings/route`, {
				route_id: id,
				name: route,
			});
			getRoutes();
			setLoading(false);
			setEdit(false);
			setId('');
			setOpen(false);
			setRoute('');
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.error,
			});
			setLoading(false);
		}
	};

	const confirm = (id: string) => {
		Modal.confirm({
			centered: true,
			title: 'Are you sure you want to delete route?',
			content: 'Double-checking before deleting route?  Confirm to proceed!',
			okText: 'Confirm',
			cancelText: 'Cancel',
			okButtonProps: { className: 'bg-red-500' },
			onOk: () => {
				deleteRoute(id);
			},
			onCancel: () => {
				setId('');
			},
		});
	};

	const confirmOTP = (id: string) => {
		Modal.confirm({
			centered: true,
			title: 'Generate new OTP for Route Login?',
			content: 'This will replace existing OTP and new OTP can be used to login. This will not effect already logins',
			okText: 'Genetate OTP',
			cancelText: 'Cancel',
			okButtonProps: { className: 'bg-PrimaryOrange' },
			onOk: () => {
				generateOTP(id);
			},
			onCancel: () => {
				setId('');
			},
		});
	};

	useEffect(() => {
		getRoutes();
	}, []);

	return (
		<UserWrapper>
			<Spin spinning={loading}>
				<div className='flex justify-between flex-col space-y-4 md:space-y-0 md:flex-row max-h-[10vh] m-4 items-center'>
					<span className=' font-bold text-[18px] text-gray-600'>Manage Routes:</span>
					<button onClick={() => setOpen(true)} className='bg-blue-500 text-PrimaryWhite py-2 px-4 font-semibold rounded flex items-center space-x-3'>
						<span>New Route</span> <MdRoute />
					</button>
				</div>

				<div className='h-[70vh] flex flex-col space-y-4 mt-2 overflow-auto'>
					{data?.data && data?.data.length > 0 ? (
						data?.data.map((item: any, index: number) => (
							<div className='bg-gray-50 rounded-md flex justify-between p-4 items-center'>
								<h1 className='font-semibold text-xl'>{item.name}</h1>
								<div className=''>Route id: {item._id}</div>
								<div className='flex space-x-4 items-center'>
									<button
										onClick={() => {
											confirmOTP(item._id);
										}}
										className='bg-PrimaryOrange text-PrimaryWhite p-2 font-semibold rounded flex items-center space-x-3'
									>
										<RiLockPasswordFill size={25} className='mr-2' /> Generate OTP
									</button>

									<button
										onClick={() => {
											setOpen(true);
											setEdit(true);
											setId(item._id);
											setRoute(item.name);
										}}
										className='bg-green-500 text-PrimaryWhite p-2 font-semibold rounded flex items-center space-x-3'
									>
										<FaEdit size={25} />
									</button>
									<button
										onClick={() => {
											confirm(item._id);
										}}
										className='bg-red-500 text-PrimaryWhite p-2 font-semibold rounded flex items-center space-x-3'
									>
										<MdDelete size={25} />
									</button>
								</div>
							</div>
						))
					) : (
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					)}
				</div>
			</Spin>
			<Drawer
				destroyOnClose={true}
				title={`Add New Route`}
				placement={'right'}
				closable={true}
				onClose={() => {
					setOpen(false);
					setRoute('');
					setId('');
					setEdit(false);
				}}
				open={open}
				key={'transfersmsvolume'}
			>
				<Spin spinning={loading}>
					<Form.Item required name='route' label={<div className='font-bold text-PrimaryBlack'>Route Name</div>} className='w-full mb-2' />
					<Input required type='text' defaultValue={route} onChange={(e) => setRoute(e.target.value)} className='border border-gray-300 rounded-md w-full py-2 px-4' />
					<button
						onClick={() => {
							if (route === '' || route === undefined || route === null) {
								setError(true);
							} else {
								edit ? editRoutes() : addRoutes();
								setError(false);
							}
						}}
						className='bg-PrimaryOrange mt-10 text-PrimaryWhite py-2 px-4 font-semibold rounded'
					>
						{edit ? 'Update' : 'Add'} Route
					</button>
					{error && <div className='bg-red-100 mt-4 rounded-md p-4'>Please enter valid route</div>}
				</Spin>
			</Drawer>

			<Modal title='Login OTP' centered open={OTPModle} footer={[]} onCancel={() => setOTPModle(false)}>
				<h1 className=' text-center py-10 text-4xl font-bold'>{otp}</h1>
				<p className='bg-red-50 p-3 text-center text-red-800'>
					<span className='font-bold'>(i) </span> OTP expires after a minute
				</p>
			</Modal>
		</UserWrapper>
	);
};

export default ManageRoutes;
