import React, { useEffect, useState } from 'react';
import UserWrapper from '../../components/UserWrapper';
import { Checkbox, Spin, Steps, Tag, Upload } from 'antd';
import axiosInstance from '../../utils/axiosInstance';
import notificationShow from '../../utils/notificationService';
import { Link, useNavigate } from 'react-router-dom';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

const ImportContact = () => {
	const [current, setCurrent] = useState(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [invalid, setInvalid] = useState<boolean>(false);
	const [fileCSV, setFileCSV] = useState<any>();
	const [groups, setGroups] = useState<any>(undefined);

	const navigate = useNavigate();

	const [groupsItem, setGroupsItem] = useState<React.Key[]>([]);

	const getCSVData = (stringValue: any) => {
		const [keys, ...rest] = stringValue
			?.trim()
			?.split('\n')
			?.map((item: any) => item.trim().split(',')); // Trim each item before splitting

		const formedArr = rest.map((item: any) => {
			const object: any = {};
			keys.forEach((key: any, index: any) => {
				const trimmedValue = item[index]?.trim(); // Trim each value before assigning
				object[key] = trimmedValue;
			});
			return object;
		});

		return formedArr;
	};

	const props: any = {
		name: 'file',
		accept: 'text/csv',
		multiple: false,
		onChange(info: any) {
			if (info.fileList.length !== 0) {
				const file = info.fileList[0].originFileObj;

				setInvalid(false);
				const reader = new FileReader();
				reader.onload = (event) => {
					//@ts-ignore
					const csvData = event.target.result;
					setFileCSV(getCSVData(csvData));
				};
				reader.readAsText(file);
			} else {
				setInvalid(true);
			}
		},
	};

	const getGroups = async () => {
		try {
			setLoading(true);

			const response = await axiosInstance.get('/groups');

			setGroupsItem(response.data.data);
			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.error,
			});
			setLoading(false);
		}
	};

	const getFormGroups = (checkedValues: CheckboxValueType[]) => {
		//@ts-ignore
		const selectedGroups = groupsItem.filter((el) => checkedValues.includes(el?._id?.toString())).map((el) => ({ label: el?.group_name, value: el?._id?.toString() }));

		setGroups(selectedGroups);
	};
	const next = () => {
		setCurrent(current + 1);
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const handleContactImport = async () => {
		try {
			setLoading(true);
			let data;
			if (groups && groups.length !== 0) {
				data = fileCSV.map((item: any, index: number) => ({
					...item,
					groups: groups.map((group: any) => group?.value),
				}));
			} else {
				data = fileCSV;
			}

			await axiosInstance.post('/contacts/import', data);

			notificationShow({
				type: 'success',
				content: 'Contacts imported successfully.',
			});
			navigate('/contacts');
			setGroups(undefined);
			setFileCSV(undefined);
			setLoading(false);
			setCurrent(0);
			setInvalid(true);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data.message,
			});
			console.log(error);

			setLoading(false);
		}
	};

	const uploadCSV = (
		<div>
			<div>
				<div className='inline-block bg-PrimaryWhite rounded-md p-4 text-center mb-4 '>
					<span>Please download sample csv file, make changes and upload here.</span>
					<br />
					<a href={'https://txtbond.s3.ap-south-1.amazonaws.com/contacts.csv'} target='_blank' className='mb-6 text-blue-500 underline underline-offset-4'>
						Download sample CSV
					</a>
				</div>
				<br />
				<b className='text-gray-600'>Upload CSV file:</b> <br />
				<Upload {...props}>
					<Tag className='my-2 px-4 py-2 bg-PrimaryOrange'>
						<button className='text-[16px] text-PrimaryWhite font-semibold '>Upload</button>
					</Tag>
				</Upload>
			</div>
		</div>
	);

	const GroupCSV = (
		<>
			{groupsItem?.length === 0 ? (
				<p className='text-PrimaryBlack grid place-items-center font-semibold text-2xl'>There is no any groups yet!</p>
			) : (
				<>
					<b className='text-gray-600'>Select groups where you want to add these contacts to:</b> <br />
					<Checkbox.Group
						className='my-4 flex flex-col space-y-4 h-[50vh] overflow-y-scroll'
						onChange={getFormGroups}
						options={groupsItem.map((el: any) => {
							return {
								label: el.group_name,
								value: el._id.toString(),
							};
						})}
					/>
				</>
			)}
		</>
	);

	const PriviewCSV = (
		<>
			<div className='text-PrimaryBlack'>
				<b className='text-gray-600'>Sample preview:</b> <br />
				{fileCSV?.slice(0, 3)?.map((el: any) => (
					<div className='bg-gray-50 rounded-xl text-sm p-2 mt-5'>
						<b>Contact name:</b> {el?.name} <br />
						<b>Nick name:</b> {el?.nick_name} <br />
						<b>Number:</b> {el?.number} <br />
						<b>Email:</b> {el?.email} <br />
						{groups && groups?.length !== 0 && (
							<>
								<b>Groups:</b>
								<span className='flex flex-wrap space-x-2'>
									{' '}
									{groups?.map((data: any, i: number) => (
										<Tag key={i}>{data?.label}</Tag>
									))}
								</span>
							</>
						)}
					</div>
				))}
			</div>
		</>
	);

	const steps = [
		{
			step: 1,
			title: 'Upload File',
			content: uploadCSV,
		},
		{
			step: 2,
			title: 'Group Contacts',
			content: GroupCSV,
		},
		{
			step: 3,
			title: 'Preview Contacts',
			content: PriviewCSV,
		},
	];

	useEffect(() => {
		setInvalid(true);
		getGroups();
	}, []);

	const contentStyle: React.CSSProperties = {
		paddingTop: '20px',
		paddingBottom: '20px',
		paddingLeft: '25px',
		paddingRight: '25px',
		backgroundColor: 'white',
		borderRadius: '5px',
		marginTop: 16,
	};

	const items = steps.map((item) => ({ key: item.title, title: item.title }));

	return (
		<UserWrapper>
			<Spin spinning={loading}>
				<div className='px-2'>
					<Steps current={current} items={items} />
					<div style={contentStyle}>{steps[current].content as any}</div>
					<div style={{ marginTop: 24 }}>
						{current > 0 && (
							<button disabled={invalid} className={`bg-PrimaryWhite text-PrimaryBlack px-4 py-1 rounded-md`} style={{ margin: '0 8px' }} onClick={() => prev()}>
								Previous
							</button>
						)}
						{current < steps.length - 1 && (
							<button disabled={invalid} className={` ${invalid ? 'bg-gray-200' : 'bg-gray-800'}  text-PrimaryWhite px-4 py-1 rounded-md`} onClick={() => next()}>
								Next Step
							</button>
						)}
						{current === steps.length - 1 && (
							<button onClick={handleContactImport} disabled={invalid} className={` ${invalid ? 'bg-gray-200' : 'bg-PrimaryBlue'}  text-PrimaryWhite px-4 py-1 rounded-md`}>
								Import Contacts
							</button>
						)}
					</div>
				</div>
			</Spin>
		</UserWrapper>
	);
};

export default ImportContact;
