import { Modal, Pagination, Select, Spin, Table, TableColumnsType, Tag } from 'antd';
import UserWrapper from '../../components/UserWrapper';
import { useEffect, useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import notificationShow from '../../utils/notificationService';
import dayjs from 'dayjs';
import { MdDeleteForever } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
var relativeTime = require('dayjs/plugin/relativeTime');

interface DataType {
	key: React.Key;
	message: string;
	number: number;
	status: boolean;
}

const SMSHistory = () => {
	const navigate = useNavigate();
	const [data, setData] = useState<any>([]);

	const [loading, setLoading] = useState<boolean>(false);
	const [paginationPage, setPaginationPage] = useState(1);
	const [paginationLimit, setPaginationLimit] = useState(25);
	const [totalRecords, setTotalRecords] = useState(0);
	dayjs.extend(relativeTime);

	const handleQueueDelete = async () => {
		try {
			setLoading(true);

			await axiosInstance.delete(`/sms/queue`);
			notificationShow({
				type: 'success',
				content: 'Message removed',
			});
			handleHistory(paginationPage, paginationLimit);
			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.message,
			});
			setLoading(false);
		}
	};

	const handleHistory = async (pagination_page: number, pagination_limit: number) => {
		try {
			setLoading(true);

			const response = await axiosInstance.get(`/sms/conversations?pagination_page=${pagination_page}&pagination_limit=${pagination_limit}`);
			setData(response.data);
			setTotalRecords(response.data.total_records);
			setLoading(false);
		} catch (error: any) {
			notificationShow({
				type: 'error',
				content: error?.response?.data?.error,
			});
			setLoading(false);
		}
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Confirm Delete?',
			centered: true,
			content: 'This will remove all messages that are on queue. SMS volume used will be refunded.',
			okText: 'Delete',
			okButtonProps: { className: 'bg-red-500 hover:!bg-red-500 text-PrimaryWhite font-semibold' },
			cancelText: 'Cancle',
			onOk: handleQueueDelete,
		});
	};

	const columns: TableColumnsType<DataType> = [
		{
			title: 'Contact Name',
			dataIndex: 'contact_name',
			key: 'contact_name',
			render: (text: string) => (
				<>
					<h1 className='text-PrimaryBlack font-bold'>{text ? text : '---'}</h1>
				</>
			),
		},
		{
			title: 'Number',
			dataIndex: 'number',
			key: 'number',
			render: (text: string) => (
				<>
					<h1 className='text-PrimaryBlack'>{text}</h1>
				</>
			),
		},
		{
			title: 'Message',
			dataIndex: 'message',
			key: 'message',
			render: (text: string) => <h1 className='text-PrimaryBlack'>{text}</h1>,
		},
		{
			title: 'Route',
			dataIndex: 'route_id',
			key: 'route_id',
			render: (text) => (
				<p>
					<Tag color={text?.name ? 'blue' : ''}> {text?.name ? text.name : 'Default'}</Tag>
				</p>
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text: string) => <Tag color={text === 'Dispatched' ? 'green' : 'yellow'}>{text}</Tag>,
		},
		{
			title: 'Sent',
			dataIndex: 'createdAt',
			key: 'createdAt',
			//@ts-ignore
			render: (text: string) => <h1 className='text-PrimaryBlack '>{dayjs(text).fromNow().toString()}</h1>,
		},
	];

	useEffect(() => {
		handleHistory(paginationPage, paginationLimit);
	}, [paginationPage, paginationLimit]);

	return (
		<UserWrapper>
			<Spin spinning={loading}>
				<div className='flex justify-between flex-col space-y-4 md:space-y-0 md:flex-row max-h-[10vh] m-4 items-center'>
					<span className=' font-bold text-[18px] text-gray-600'>SMS History:</span>
					<button onClick={confirm} className='bg-red-500 text-PrimaryWhite flex items-center space-x-2 py-2 px-4 font-semibold rounded'>
						<MdDeleteForever className='text-xl' /> <span>Delete all Queued SMS</span>
					</button>
				</div>
				<div className='max-h-[70vh]'>
					<Table
						columns={columns}
						dataSource={data?.data}
						style={{ overflowY: 'hidden' }}
						pagination={false}
						loading={loading}
						sticky={{ offsetHeader: 0 }}
						scroll={{ x: '1500', y: '60vh' }}
						rowKey={'history'}
					/>
				</div>
				<div className=' h-[5vh] flex justify-between mt-5'>
					<div>
						Records per page:
						<Select
							className='ml-2'
							onChange={(value) => {
								setPaginationLimit(value);
							}}
							defaultValue={25}
							options={[
								{
									value: '5',
									label: 5,
								},

								{
									value: '25',
									label: 25,
								},
								{
									value: '50',
									label: 50,
								},
								{
									value: '100',
									label: 100,
								},
							]}
						></Select>
					</div>
					<div>
						<Pagination
							showSizeChanger={false}
							defaultCurrent={1}
							total={totalRecords}
							pageSize={paginationLimit}
							responsive={true}
							onChange={(value) => {
								setPaginationPage(value);
							}}
						/>
					</div>
				</div>
			</Spin>
		</UserWrapper>
	);
};

export default SMSHistory;
