import { Form, Input, Spin } from 'antd';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Logo from '../components/Logo';
import axiosInstance from '../utils/axiosInstance';
import notificationShow from '../utils/notificationService';

const ResetPassword = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string>('');
	const { email } = useParams();

	const resetPassword = async () => {
		try {
			setLoading(true);
			await form.validateFields();
			const formValues = form.getFieldsValue();
			await axiosInstance.post('/users/reset-password', {
				email: email,
				password_reset_otp: formValues.password_reset_otp,
				password: formValues.password,
				confirm_password: formValues.confirm_password,
			});
			form.resetFields();
			setLoading(false);
			notificationShow({
				type: 'success',
				content: 'Password reset successfully',
			});
			window.location.href = '/';
		} catch (error: any) {
			setLoading(false);
			setError(error?.response?.data?.message);
		}
	};

	return (
		<div className='flex flex-col md:items-center mx-4 md:mx-0 justify-center min-h-[100dvh] gap-4 '>
			<div className='flex justify-center'>
				<Logo />
			</div>
			<div className='bg-white border border-gray-200 shadow'>
				<div className=' p-4 lg:w-[30dvw] md:w-[50dvw] w-full'>
					<div className='w-full flex flex-col'>
						<div className='mb-6 mt-2 text-center text-PrimaryBlack'>
							<h1 className='text-2xl mb-2 font-bold'>Reset Your Password</h1>
							<p className='text-sm'>please enter OTP received in your email.</p>
						</div>

						<Form autoComplete='off' size='small' layout='vertical' form={form} className='w-full '>
							<Form.Item
								required
								name='password_reset_otp'
								label={<div className='font-bold text-PrimaryBlack'>OTP</div>}
								rules={[
									{
										required: true,
										message: 'OTP is required.',
									},
								]}
								className='w-full'
							>
								<Input type='number' className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>

							<Form.Item
								name='password'
								label={<div className='font-bold text-PrimaryBlack'>Password</div>}
								rules={[
									{
										required: true,
										message: 'Password is required.',
									},
								]}
								className='w-full'
							>
								<Input.Password className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>
							<Form.Item
								name='confirm_password'
								label={<div className='font-bold text-PrimaryBlack'>Confirm Password</div>}
								rules={[
									{
										required: true,
										message: 'Confirm Password is required.',
									},
								]}
								className='w-full'
							>
								<Input.Password className='border border-gray-300 rounded-md w-full py-2 px-4' />
							</Form.Item>
						</Form>

						<Spin spinning={loading}>
							<button onClick={resetPassword} className='disabled:bg-gray-600 disabled:cursor-not-allowed  bg-PrimaryOrange text-white font-semibold px-4 py-2 rounded-md w-full mt-2 mb-8'>
								Reset Password
							</button>
						</Spin>

						{error && <div className='bg-red-50 text-red-500 rounded-md text-sm text-center py-2 mb-4'>{error}</div>}

						<div className='text-center text-sm text-PrimaryBlack'>
							Already using TXTBond? &nbsp;
							<Link to='/' className='cursor-pointer font-bold underline underline-offset-4'>
								Sign in here
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className='text-[12px] text-PrimaryBlack text-center px-4'>
				For assistance, contact our support team at{' '}
				<a href='mailto:txtbond@gmail.com' className='font-bold '>
					txtbond@gmail.com.
				</a>
			</div>
		</div>
	);
};

export default ResetPassword;
